<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-card elevation="2" outlined class="mx-auto mt-2" max-width="600" width="600">
        <v-card-title>{{$t('flashcards.congratulationsTitle')}}</v-card-title>
        <v-card-text>
          <p class="text-h4 text--primary">{{$t('flashcards.sessionCompleted')}}</p>
          <div class="text--primary">
            {{$t('flashcards.yourResultPrefix')}} {{knownCards}} {{$t('flashcards.yourResultSuffix')}} {{cardsNo}}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="space-around" align="center">
              <v-btn color="primary" large @click="config">{{$t('flashcards.configureAnotherSet')}}</v-btn>
              <v-btn color="success" large @click="end">{{$t('flashcards.greatContinue')}}</v-btn>
            </v-row></v-container>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>

</template>

<script>
export default {
  name: "FlashcardsResult",
  props:{
    knownCards: Number,
    cardsNo: Number
  },
  methods:{
    end(){
      this.$emit("gotoGame");
    },
    config(){
      this.$emit("gotoConfig");
    }
  }
}
</script>

<style scoped>

</style>