import Vue from 'vue'
import Vuex from "vuex";
import state from "@/state/state";
import axios from "axios";

Vue.use(Vuex);

export default {
    state:{
        token:"",
        firstSlide:"main"
    },
    mutations:{
        setToken(state,tok){
           state.token = tok
            axios.interceptors.request.use(function(config){
                config.headers.Authorization =  tok;
                return config;
            });
        },
        setFirstSlide(state,slide){
            state.firstSlide = slide;
        }
    }
};