<template>
  <v-footer padless fixed>
    <v-card flat  width="100%" class="red lighten-1 text-center">
      <v-card-text class="pa-0">
        <span v-for="(item,i) in items" :key="i" v-if="!isMobile || item.mobile">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn  class="mx-4" icon v-bind="attrs" v-on="on" @click="scrollMe(item.to)">
              <v-icon size="24px">
                {{ item.icon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>

        </span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import ismobile from  "@/plugins/ismobile";
import Vue from "vue";
export default {
  name: "Footer",
  data(){
    return{

      items:[
        {icon:"mdi-home",to:"#top",tooltip:this.$t('landingpage.footertop'),mobile:true},
        {icon:"fa-solid fa-list",to:"#inproduct",tooltip:this.$t('landingpage.footerinside'),mobile:false},
        {icon:"fa-solid fa-video",to:"#videos",tooltip:this.$t('landingpage.footervideo'),mobile:false},
        {icon:"fa-solid fa-person-circle-question",to:"#forme",tooltip:this.$t('landingpage.footerforme'),mobile:false},
        {icon:"fa-solid fa-person-chalkboard",to:"#aboutme",tooltip:this.$t('landingpage.footerauthor'),mobile:false},
        {icon:"fa-solid fa-mobile-screen-button",to:"#fragments",tooltip:this.$t('landingpage.footerdemo'),mobile:true},
        {icon:"fa-solid fa-dollar-sign",to:"#price",tooltip:this.$t('landingpage.footerprice'),mobile:true},
        {icon:"fa-solid fa-question",to:"#faq",tooltip:this.$t('landingpage.footerfaq'),mobile:false},
        {icon:"fa-solid fa-cart-shopping",to:"buy",tooltip:this.$t('landingpage.footerbuy'),mobile:true},
      ],
    }
  },
  methods:{
    scrollMe(to){
      if(to==="buy"){
        return document.getElementById("ctabtn").click();
      }
      return this.$vuetify.goTo(to,{duration:1000,offset:50});
    }
  },
  created() {
    Vue.use(ismobile);
  },
  mixins:[ismobile],
}
</script>

<style scoped>

</style>