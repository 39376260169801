<template>
  <div>

    <v-card style="width: 100%;">
      <v-card-title id="">
        {{$t('verbTable.title')}}
      </v-card-title>
      <v-container id="tour-filters">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6">
            <label>{{$t('verbTable.verbsToShow')}}</label><br/>
      <ConfigRadio :value="verbType" field="verbType"  @input="updateFields('verbType',$event)" :button-values="verbTypes" ></ConfigRadio>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="this.$t('verbTable.find')"
                single-line
                hide-details
                id="v-step-1"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-simple-table dense >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Verb
            </th>
            <th class="text-left">
              Oversettelse
            </th>
            <th></th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <tr
              v-for="(item,i) in verbs"
              :key="item.id"
              @click="showDialog(item.id)"
              v-show="!hidden[item.id]"
              :id="'row'+i"
          >
            <td>
              <v-lazy v-model="item.isActive">
                <span >
                  <v-icon>mdi-chevron-down</v-icon>
                  {{ item.verb.word.text }}
                  <AudioPlayer :audio-url="item.verb.word.audio"></AudioPlayer>
                  <VerbWithModal :initem="item" v-model="dialogs[item.id]" :seq-no="i"  ></VerbWithModal>
                </span>
              </v-lazy>
            </td>
            <td>
              <v-lazy v-model="item.isActive">
                <span>
                <WordCutter :word="item.verb.word.translation"></WordCutter>
                <AudioPlayer :audio-url="item.verb.word.audioTranslation"></AudioPlayer>
                </span>
              </v-lazy>
            </td>
            <td>
              <v-lazy v-model="item.isActive">
                <StateChanger :id="item.id" type="know" :current-state="item.know" ></StateChanger>
              </v-lazy>
            </td>
            <td>
              <v-lazy v-model="item.isActive">
                <StateChanger :id="item.id" type="important" :current-state="item.important"></StateChanger>
              </v-lazy>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>


    </v-card>
    <MainTour tour-id="verbtable" />
  </div>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer";
import StateChanger from "@/components/StateChanger";
import WordCutter from "@/components/WordCutter";
import VerbWithModal from "@/components/VerbWithModal";
import {checkMatch} from "@/functions/string";
import ConfigRadio from "@/components/Flashcards/ConfigRadio";
import MainTour from "@/components/Tours/MainTour";

export default {
  name: "VerbTable2",
  components: {MainTour, ConfigRadio, VerbWithModal, WordCutter, StateChanger, AudioPlayer},
  methods:{
    showDialog(itemId){
      this.$set(this.dialogs,itemId,true);
    },
    updateFields(name,val){
      this[name] = val;
      this.searchVerbs();
    },
    searchVerbs(){
      clearTimeout(this.timeoutSearch);
      let searchPhrase = this.search;
      let tmpthis = this;
      this.timeoutSearch = setTimeout(function(){
        tmpthis.verbs.forEach(function(el,index){
          let hideAfter = false;

          if((tmpthis.verbType ==="onlyUnknown" && el.know===1)
              || (tmpthis.verbType ==="onlyKnown" && el.know===0)
              || (tmpthis.verbType ==="onlyImportant" && el.important===0))
            hideAfter = true;
          if(!checkMatch(searchPhrase,el.verb.word.text+" "+el.verb.word.translation+" "+el.verb.form1.text+" "+el.verb.form2.text+" "+el.verb.form3.text))
            hideAfter = true;
          tmpthis.$set(tmpthis.hidden, el.id, hideAfter);

        });
      },200);
    },
    refreshVerbs(){
      let dialogs = [];
      let hidden = [];
      let verbs = this.$store.state.verbs;
      verbs.forEach(function(item){
        item.isActive = false;
        item.isHidden = false;
        dialogs[item.id] = false;
        hidden[item.id] = false;
      });
      this.dialogs = dialogs;
      this.hidden = hidden;
      this.verbs = verbs;
      return {verbs:verbs,dialogs:dialogs,hidden:hidden};
    }
  },
  watch:{
    search(){
      this.searchVerbs();
    }
  },
  created() {
    this.$store.commit("tourAvailable",true);
    this.$store.commit("endTour");
  },
  destroyed() {
    this.$store.commit("tourAvailable",false);
  },
  data(){
    let d = this.refreshVerbs();
    return{
      dialogs:d.dialogs,
      timeoutSearch:-1,
      search: '',
      verbs:d.verbs,
      hidden:d.hidden,
      verbType:"all",
      verbTypes:this.$t("verbTable.types"),
    }
  }
}
</script>

<style scoped>
td:nth-child(3), td:nth-child(4),th:nth-child(3), th:nth-child(4){
  padding:0!important;
  width:1px;
}
td:nth-child(1), td:nth-child(2){
  padding: 0px 0px 0px 6px!important;
}
td{
  cursor: pointer;
}
</style>