<template>
  <div>
Instructions
    <button @click="clicked">Test</button>
  </div>
</template>

<script>
export default {
  name: "Instructions",
  methods:{
    clicked(){

    }
  }
}
</script>

<style scoped>

</style>