<template>
  <div :class="'img '+visible?'vueTourImage':''" style="position: relative">
    <img :src="imageData.src" class="img" id="img1">
    <div class="imgElement" v-for="(el,j) in imageData.elements" :key="j" :style="setElementStyle(el.top,el.left)" >
      <Tooltip :text="el.text" />
    </div>
    <div :class="visible?'vueTourCenter':''"></div>
  </div>
</template>

<script>
import Tooltip from "@/components/FromImages/Tooltip";

export default {
  name: "FImage",
  components: {Tooltip},
  props:{
    imageData:Object,
    visible:Boolean
  },
  data(){
    return{
    }
  },
  methods: {

    setElementStyle(top,left){
      return "top:"+top+"%; left:"+left+"%;";
    }
  }
}
</script>

<style scoped>
.img{
  max-height: calc(100vh - 64px - 24px - 45px - 6px);
  max-width: 100%;
  margin-top:6px;
}

.imgElement{
  position: absolute;
  height:36px;
  width:36px;
}
.vueTourCenter{
  position:absolute;
  top:50%;
  left:50%;
  height:1px;
  width:1px;
  background-color: rgba(255,255,255,0.01);
}
@media screen and (max-width: 600px), screen and (max-height:600px) {
  .imgElement {
    height: 18px;
    width: 18px;
  }
}
</style>