<template>
  <FlashcardsIntro @gotoflashcards="gotoFlashcards" v-if="status=='intro'"></FlashcardsIntro>
  <Flashcards v-else-if="status=='game'" @endGame="gotoResult" @backToStart="gotoConfig"></Flashcards>
  <FlashcardsResult v-else-if="status=='result'" @gotoConfig="gotoConfig" @gotoGame="gotoFlashcards" :known-cards="knownCards" :cards-no="cardsNo"></FlashcardsResult>
</template>

<script>
import ConfigRadio from "@/components/Flashcards/ConfigRadio";
import FlashcardsConfig from "@/components/Flashcards/FlashcardsConfig";
import FlashcardsIntro from "@/components/Flashcards/FlashcardsIntro";
import Flashcards from "@/components/Flashcards/Flashcards";
import FlashcardsResult from "@/components/Flashcards/FlashcardsResult";
export default {
  name: "FlashcardsMenu",
  components: {FlashcardsResult, Flashcards, FlashcardsIntro, FlashcardsConfig, ConfigRadio},
  methods:{
    gotoFlashcards(){

      this.status = "game";
    },
    gotoResult(known,all){
      this.cardsNo = all;
      this.knownCards = known;
      this.status = "result";
    },
    gotoConfig(){
      this.status = "intro";
    }
  },
  data(){
    return{
      knownCards:0,
      cardsNo:0,
      status:"intro",
    }
  }
}
</script>

<style scoped>

</style>