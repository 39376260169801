import VueRouter from 'vue-router'
import Instructions from "@/components/Instructions";
import VerbTable from "@/components/VerbTable";
import Vue from "vue";
import VerbTable2 from "@/components/VerbTable2";
import Flashcards from "@/components/Flashcards/Flashcards";
import FlashcardsMenu from "@/components/Flashcards/FlashcardsMenu";
import Tasks from "@/components/Tasks/Tasks";
import TasksMenu from "@/components/Tasks/TasksMenu";
import FromImages from "@/components/FromImages/FromImages";
import Register from "@/components/Registration/Register";
import Login from "@/components/Registration/Login";
import Home from "@/components/Home";
import VideoPlayer from "@/components/VideoPlayer";
import Landingpage from "@/components/LandingPage/Landingpage";
import Subscribe from "@/components/Registration/Subscribe.vue";

Vue.use(VueRouter);



export default new VueRouter({
    mode:"history",
    routes: [
        { path: '/home', component: Home, meta:{requiresAuth:true},name:"Home" },
        { path: '/instructions', component: Instructions, meta:{requiresAuth:true},name:"Main" },
        { path: '/verbs', component: VerbTable2, meta:{requiresAuth:true} },
        { path: '/flashcards', component: FlashcardsMenu, meta:{requiresAuth:true} },
        { path: '/tasks/:category', component: TasksMenu, meta:{requiresAuth:true} },
        { path: '/fromimages', component: FromImages, meta:{requiresAuth:true} },
        { path: '/register', component: Register },
        { path: '/Login', component: Login , props: true},
        { path: '/Subscribe', component: Subscribe , props: true},
        { path: '/video',component:VideoPlayer},
        { path: '/', component: Landingpage,name:"landingpage" },

    ]
})