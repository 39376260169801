<template>
  <v-dialog transition="dialog-bottom-transition" max-width="900" v-model="show" @input="handleInput" content-class="dialogverbs">
    <template v-slot:default="dialog">

      <v-card v-if="show">
        <v-toolbar color="primary" dark>{{$t('logout.logoutTitle')}}</v-toolbar>
        <v-card-text>
          <h2 class="mb-4">{{$t('logout.logoutQuestion')}}</h2>

          <div>
            <v-btn style="float:left;" class="warning mb-4" @click="logoutAll">
              {{$t('logout.logoutAllDevices')}}
            </v-btn>
            <v-btn style="float:right;" class="error  mb-4" @click="logout">
              {{$t('logout.logoutThisDevice')}}
            </v-btn>
          </div>
          <div style="clear: both;"></div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="show = false">{{$t('logout.cancel')}}</v-btn>
        </v-card-actions>
      </v-card>

    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "Logout",
  props:{
    value:Boolean,
  },
  methods:{
    logout(){
      axios.post("/verbs/public/api/user/logout",{});
      this.$store.commit("setToken","");
      window.location="/";
    },
    logoutAll(){
      axios.post("/verbs/public/api/user/logoutAll",{});
      this.$store.commit("setToken","");
      window.location="/";
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>