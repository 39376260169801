import Vue from 'vue'
import Vuex from 'vuex'
import vuexI18n from 'vuex-i18n';
import State2 from './persistentState';
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import tasks from "@/state/tasks";
import sampleverbs from "@/assets/verbssample.json";


Vue.use(Vuex);


export default new Vuex.Store({
    state: {
        language: "en",
        count: 0,
        videoTimer:0,
        pricing:"annual",
        productPrice:599,
        productPriceDiscount:499,
        productPriceCourseDiscount:539,
        productPricePLN:260,
        subscriptionTo:"?",
        currency:"NOK",
        discountDateTo:new Date("2022-12-18T00:00:00"),
        stats:{
            verbsNo:345,
            tasksNo:0,
            subtasksNo:0,
        },
        isAdvertisement:false,
        tour:false,
        tourAvailable:false,
        verbs:sampleverbs,
        flashCards:{
            level:1,
            side:'pl',
            words_per_session:"20",
            known_words:'all',
            important_words:'all',
            show_images:'onlyAnswers',
        },
        h5pState:{
          xapiInitiated:false,
          currentTask:""
        },
        timeoutFlashcardsChange:-1,
        currentAudio:null
    },
    mutations: {
        init(state){
            console.log(Vue.i18n.locale());
            state.language = Vue.i18n.locale()
        },
        setSubscriptionTo(state,value){
            state.subscriptionTo = value;
        },
        setVideoTimer(state,time){
            state.videoTimer = time;
        },
        switchLanguage(state){
            if(state.language ==="pl")
                state.language = "en";
            else
                state.language = "pl";
            Vue.i18n.set(state.language);
        },
        setCurrency(state,currency){
          state.currency = currency;
        },
        setPricing(state,pricing){
            state.pricing = pricing;
        },
        switchCurrency(state){
            if(state.currency === "NOK")
                return state.currency = "PLN";
            state.currency = "NOK";
        },
        setH5PCurrentTask(state,data){
            state.h5pState.currentTask = data;
        },
        setH5PXapiInitiated(state){
          state.h5pState.xapiInitiated = true;
        },
        startTour(state){
          state.tour=true;
        },
        setStats(state,stats){
            state.stats = stats;
        },
        markAsAdvertisement(state){
            state.isAdvertisement = true;
        },
        endTour(state){
          state.tour = false;
        },
        tourAvailable(state,isAvailable){
          state.tourAvailable = isAvailable;
        },
        increment (state) {
            state.count++
        },
        setVerbs(state,verbs){
            state.verbs = verbs;
        },
        setFlashcards(state,flashcards){
            for (let key in flashcards) {
                if (!flashcards.hasOwnProperty(key)) continue;
                state.flashCards[key] = flashcards[key];
            }
        },
        setCurrentAudio(state,audio){
            if(state.currentAudio !==null) {
                state.currentAudio.pause();
                state.currentAudio.currentTime = 0;
            }
            state.currentAudio = audio;
        },
        setVerbStatus(state,{id,type,changeTo}){
            let findIndex=-1;
            if(changeTo)
                changeTo = 1;
            else
                changeTo = 0;
            state.verbs.forEach((value,index)=>{
                if(value.id == id){
                    findIndex = index;
                    return;
                }
            });
            if(findIndex==-1) return;
            if(type=="know") {
                let s = state.verbs[findIndex];
                s.know = changeTo;
                state.verbs = [...state.verbs.filter(element => element.id !== id), s]
            }
            if(type=="important"){
                let s = state.verbs[findIndex];
                s.important = changeTo;
                state.verbs = [...state.verbs.filter(element => element.id !== id), s]
            }

        }
    },
    actions:{
        switchVerbStatus({state},{id,type,changeTo}){
            axios.post("/verbs/public/api/verbs/changeState",{verbId:id,type:type,changeTo:changeTo});
        },
        setFlashCardConfig({state},{field,value}){
            state.flashCards[field] = value;
            clearTimeout(state.timeoutFlashcardsChange);
            state.timeoutFlashcardsChange = setTimeout(function(){
                axios.post('/verbs/public/api/config/updateConfigFlashcards',state.flashCards);
            },5000);
        }
    },
    modules:{
        persistent:State2,
        moduleTasks:tasks
    },
    plugins: [createPersistedState({
        paths:["persistent"]
    })]
})