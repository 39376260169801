let plugin = {};
plugin = {
        computed:{
            isMobile(){
                return window.innerWidth<600;
            }
        },

    }

export default plugin;