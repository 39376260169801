<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{$t('registration.loginTitle')}}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit="loginForm">
                  <v-text-field prepend-icon="mdi-account" name="login" :label="this.$t('registration.loginField')" type="text" v-model="login" :disabled="isAuthenticating" v-if="showEmailField"></v-text-field>
                  <div v-if="showToken">
                    <label>{{$t('registration.tokenInstructions')}}</label>
                    <v-otp-input length="6" type="number" v-model="token" :disabled="tokenDisabled" @finish="logmeToken" ></v-otp-input>
                  </div>
                  <v-alert type="error" v-if="errorType === 'noEmail'">
                    {{$t('registration.noEmailError')}}
                  </v-alert>
                  <v-alert type="error" v-if="errorType === 'wrongEmail'">
                    {{$t('registration.wrongEmailError')}}
                  </v-alert>
                  <v-alert type="error" v-if="errorType === 'wrongLink'">
                    {{$t('registration.wrongLinkError')}}
                  </v-alert>
                  <v-alert type="error" v-if="errorType === 'wrongToken'">
                    {{$t('registration.wrongTokenError')}}
                  </v-alert>
                  <v-alert type="error" v-if="errorType === 'wrongToken3'">
                    {{$t('registration.threeTimesTokenError')}}
                  </v-alert>
                  <v-alert type="error" v-if="errorType === 'noSubscription'">
                    {{$t('registration.noSubscriptionError')}}
                  </v-alert>
                  <v-alert type="error" v-if="errorType === 'hasProduct'">
                    {{$t('registration.hasProductError')}}
                  </v-alert>
                  <v-alert type="error" v-if="errorType === 'other'">
                    {{$t('registration.unknownError')}}
                  </v-alert>
                  <v-alert type="success" v-if="errorType === 'success'">
                    {{$t('registration.successMessage')}}
                  </v-alert>
                </v-form>
              </v-card-text>
              <v-card-actions v-if="!showToken">
                <v-btn color="warning" href="/landingpage">{{$t('registration.discoverProduct')}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="logMe">{{$t('registration.loginButton')}}</v-btn>
              </v-card-actions>
              <v-overlay :value="isAuthenticating" absolute="true">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay>
            </v-card>
          </v-flex>

        </v-layout>
      </v-container>
    </v-content>
    <Subscribe v-model="subscribeShow" :inemail="login"></Subscribe>
  </v-app>
</template>

<script>
import axios from "axios";
import Subscribe from "@/components/Registration/Subscribe.vue";

export default {
  name: 'Login',
  components: {Subscribe},
  props: {
    source: String,
  },
  computed:{
    showEmailField(){
      if(this.showToken)
        return false;
      if(this.errorType===null || this.errorType==="noEmail" || this.errorType==="wrongEmail" || this.errorType==="wrongLink" || this.errorType==="other" || this.errorType==="noSubscription" || this.errorType==="wrongToken3"){
        return true;
      }
      return false;
    }
  },
  mounted() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if(params.email !==null && params.email.length>0){
      this.showEmailField = false;
      this.login = params.email;
      this.showToken = true;
    }
    if(params.link !==null && params.link.length>0){
      this.loginMe("link","",params.link);
    }
  },
  methods:{
    loginMe(method,token,link){
      this.isAuthenticating = true;
      var tmpthis = this;
      axios.post("/verbs/public/api/user/token",{link:link,token:token,email:this.login}).then(function(response){
        if(typeof response.data.token !== "undefined"){
          tmpthis.$store.commit("setToken",response.data.token);
          window.location = location.protocol + '//' + location.host + location.pathname;
          return;
        }
        tmpthis.isAuthenticating = false;
        if(response.data.error==="wrongToken"){
          tmpthis.tokenWrong ++;
          tmpthis.tokenDisabled = false;
          tmpthis.token = "";
          if(tmpthis.tokenWrong > 2){
            tmpthis.tokenWrong = 0;
            tmpthis.showToken = false;
            return tmpthis.errorType="wrongToken3";
          }
          return tmpthis.errorType="wrongToken";
        }

        tmpthis.errorType = "wrongLink";
      }).catch(function(response){
        tmpthis.isAuthenticating = false;
        tmpthis.errorType="other";
      });
    },
    logmeToken(){
      this.tokenDisabled = true;
      this.loginMe("token",this.token,"");
    },
    validateEmail(email){
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    loginForm(ev){
      ev.preventDefault()
      this.logMe(false)
    },
    logMe(buy){
      this.errorType=null;
      this.isAuthenticating = true;


      if(!this.validateEmail(this.login)){
        this.isAuthenticating = false;
        return this.errorType="wrongEmail";
      }

      var tmpthis = this;
      axios.post("/verbs/public/api/user/token",{email:this.login}).then(function(response){
        tmpthis.isAuthenticating = false;
        if(typeof response.data.error !== "undefined"){
          if(buy === true){
            return window.location = "https://nataliapasternak.pl/verbs/public/api/product/pay?product_code=verbs&email="+tmpthis.login;
          }
          if(response.data.error==="noSubscription"){
            tmpthis.subscribeShow=false;
            tmpthis.subscribeShow=true;
            return tmpthis.errorType = "noSubscription";
          }
          return tmpthis.errorType="noEmail";
        }

        if(buy ===true){
          return tmpthis.errorType="hasProduct";
        }

        tmpthis.showToken = true;
        tmpthis.errorType="success";
      }).catch(function(response){
        tmpthis.isAuthenticating = false;
        tmpthis.errorType="other";
      });
    },
    buyMe(){
      this.logMe(true);
    }
  },
  data:function(){
    return {
      login:"",
      password:"",
      isAuthenticating:false,
      errorType:null,
      token:"",
      showToken:false,
      tokenDisabled:false,
      tokenWrong:0,
      subscribeShow:false,
    }
  },
};
</script>

<style scoped>
#inspire{
  background-image: url("@/assets/images/bgpagelogin.jpg");
  height: 100vh;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>
