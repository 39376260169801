<template>
  <v-app>
    <v-app-bar  clipped-left app>
      <div style="display: inline" class="mr-5" v-for="index in 20" :key="index">
      <v-skeleton-loader
          v-bind="attrs"
          type="chip"

      ></v-skeleton-loader>

      </div>
  </v-app-bar>
    <v-navigation-drawer   class="deep-purple accent-4"
                           clipped  app>
      <v-skeleton-loader
          v-bind="attrs"
          type="heading,list-item-avatar-three-line@5"
      ></v-skeleton-loader>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <v-skeleton-loader
            v-bind="attrs"
            type="card"

        ></v-skeleton-loader>
        <v-skeleton-loader
            v-bind="attrs"
            type="table"

        ></v-skeleton-loader>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>