<template>
<span v-if="word.length>16 && isMobile">
  <span v-if="!showFull">
  {{ word.substring(0,16) }}<v-btn @click="show($event)" icon x-small><v-icon>mdi-dots-horizontal</v-icon></v-btn>
  </span>
  <span v-else>
    {{ word }}
  </span>
</span>
  <span v-else>
                {{ word }}
              </span>
</template>

<script>
export default {
  name: "WordCutter",
  props:{
    word:String
  },
  data:function(){
    return{showFull:false}
  },
  methods:{
    show(event){
      event.stopPropagation();
      this.showFull = true;
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
}
</script>

<style scoped>

</style>