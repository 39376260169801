<template>
  <v-container fluid >
    <v-row align="center"
           justify="center" >


  <v-card elevation="2" outlined class="mx-auto mt-2 flex-parent" max-width="600" width="600">
    <v-progress-linear class="progress" v-model="progress" height="15" striped>
      <span class="text-caption" :style="'font-weight:bold;background-color:'+this.progressTextBgColor+';color:'+this.progressTextColor">{{ progressText }}</span>
    </v-progress-linear>
    <div class="flash-content" style="position: relative">
      <v-card-text style="padding:0 16px;">

        <v-img class="white--text mx-auto imgheight" aspect-ratio="1" :src="currentImageWord"  contain ></v-img>
        <div style="height:50px;">
          <p class="text-h4 text-center text--primary">
            <AudioPlayer :text="currentWord" :audio-url="currentAudio"></AudioPlayer>
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="reveal = true" class="mx-auto" color="primary">
          {{$t('flashcards.showTranslation')}} <v-icon right>mdi-arrow-u-left-bottom</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-if="reveal" class="transition-fast-in-fast-out v-card--reveal flash-content" style="height: 100%;background:white;">
          <v-card-text class="pb-0">
            <v-img class="white--text align-end mx-auto imgheight" aspect-ratio="1" :src="currentImageTranslation" contain></v-img>
            <div style="height:50px;">
              <p class="text-h5 text-center text--primary ">
                <AudioPlayer :text="currentTranslation" :audio-url="currentAudioTranslation"></AudioPlayer>
              </p>
            </div>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn @click="nextFlashcard(false)" class="mx-auto" color="error">
              <v-icon left>mdi-account-question-outline</v-icon> {{$t('flashcards.dontKnow')}}
            </v-btn>
            <v-btn @click="nextFlashcard(true)" class="mx-auto" color="success">
              <v-icon left>mdi-check</v-icon> {{$t('flashcards.know')}}
            </v-btn>
          </v-card-actions>
        </div>
      </v-expand-transition>

    </div>
    <footer class="flashcards_footer">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="backToStart" >
            <v-icon>
              fa-solid fa-rotate-left
            </v-icon>
          </v-btn>
        </template>
        <span>{{$t('flashcards.backToConfigTooltip')}}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="showInstruction=true">
            <v-icon>
              mdi-help-circle-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{$t('flashcards.instructionTooltip')}}</span>
        <FlashcardsInstruction v-model="showInstruction" />
      </v-tooltip>
      <StateChanger type="important" :id="this.currentObj.id" :current-state="this.currentObj.know" ></StateChanger>
    </footer>

  </v-card>
    </v-row>
  </v-container>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer";
import StateChanger from "@/components/StateChanger";
import FlashcardsInstruction from "@/components/Flashcards/FlashcardsInstruction";
export default {
  name: "Flashcards",
  components: {FlashcardsInstruction, StateChanger, AudioPlayer},
  created() {

    let lvl = this.$store.state.flashCards.level;
    let known = this.$store.state.flashCards.known_words;
    let weights = [];
    if(lvl === "1")
      weights = [7,3,1];
    else if(lvl==="2")
      weights = [1,6,3];
    else if(lvl==="3")
      weights = [1,2,7];
    else
      weights=[1,1,1];
    this.cardsNo = this.$store.state.flashCards.words_per_session;
    let allverbs = JSON.parse(JSON.stringify(this.$store.state.verbs));
    if(allverbs.length<this.cardsNo){
      this.cardsNo = allverbs.length;
    }
    let elsWeights = [];
    allverbs.forEach(function(item,index){
      item.weight = 1;
      item.weight *= weights[item.level-1];
      if((known === "onlyUnknown" && item.know === 0) ||
          (known === "onlyKnown" && item.know === 1) ||
          (known === "onlyImportant" && item.important === 1))
      {
          item.weight *= 10;
      }else{
        item.weight*=0.01;
      }

    });
  console.log(allverbs);
    let pickedVerbs = [];
    for(let i=0;i<this.cardsNo;i++){
      let sum = this.calculateWeights(allverbs);
      let random = Math.random()*sum;
      for(let j=0;j<allverbs.length;j++){
        if(random<allverbs[j].sumWeights){
          pickedVerbs.push(allverbs[j]);
          allverbs.splice(j,1);
          break;
        }
      }
    }

    this.flashcards = pickedVerbs;
  },
  methods:{
    calculateWeights(allverbs){
      let sum =0;
      allverbs.forEach(function(item,index){
        sum+= item.weight;
        item.sumWeights = sum;
      });
      return sum;
    },
    nextFlashcard(known){
      this.$store.dispatch("switchVerbStatus",{id:this.currentObj.id,type:"know",changeTo:known});
      this.$store.commit("setVerbStatus",{id:this.currentObj.id,type:"know",changeTo:known});
      if(known)
        this.knownCards++;
      if(this.cardNo === this.cardsNo-1)
        return this.endProcess();
      this.reveal = false;
      this.cardNo++;
    },
    endProcess(){
      this.$emit("endGame",this.knownCards,this.cardsNo);
    },
    backToStart(){
      this.$emit("backToStart");
    }
  },
  computed:{
    progressTextColor(){
      if(this.progress<=55){
        return "#000000";
      }
      return "#FFFFFF";
    },
    progressTextBgColor(){
      if(this.progress<45 || this.progress>55){
        return "";
      }
      return "rgba(255,255,255,.6)";
    },
    progressText(){
        return (this.cardNo+1)+" z "+this.cardsNo;
    },
    progress(){
      return 100*(this.cardNo+1)/this.cardsNo;
    },
    currentWord(){
      if(this.$store.state.flashCards.side==="no")
        return this.flashcards[this.cardNo].verb.word.text;
      return this.flashcards[this.cardNo].verb.word.translation;
    },
    currentTranslation(){
      if(this.$store.state.flashCards.side==="no")
        return this.flashcards[this.cardNo].verb.word.translation;
      return this.flashcards[this.cardNo].verb.word.text;
    },
    currentAudio(){
      if(this.$store.state.flashCards.side==="no")
        return this.flashcards[this.cardNo].verb.word.audio;
      return this.flashcards[this.cardNo].verb.word.audioTranslation;
    },
    currentAudioTranslation(){
      if(this.$store.state.flashCards.side==="no")
        return this.flashcards[this.cardNo].verb.word.audioTranslation;
      return this.flashcards[this.cardNo].verb.word.audio;
    },
    currentImageWord(){
      if(this.$store.state.flashCards.show_images ==="always" || this.$store.state.flashCards.side==="pl")
        return this.flashcards[this.cardNo].imageUrl;
      return "/images/flashcards/question.jpg";
    },
    currentImageTranslation(){
      return this.flashcards[this.cardNo].imageUrl;
    },
    currentObj(){
      return this.flashcards[this.cardNo];
    }
  },
  data: () => ({
    reveal: false,
    cardNo:0,
    cardsNo:25,
    knownCards:0,
    flashcards:[],
    showInstruction:false
  }),
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.flashcards_footer button{
  margin:5px 10px;
}
.flex-parent{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px - 12px);
}
.imgheight{
  max-height: calc(100vh - 64px - 12px - 15px - 46px - 100px);
}
.flash-content{
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.progress{
  flex-shrink: 0;
}
</style>