<template>
  <v-container>
    <p class="text-h6">
      {{$t('landingpage.productForYou')}}
    </p>

    <v-list dense >
      <v-list-item-group color="primary" v-model="selectedItems" multiple>
        <v-list-item
            v-for="(item, i) in items"
            :key="i"
            ref="item2"
            :id="'item'+i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle v-html="item.text" class="wrap-text subtitle"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <p class="text-h6">
      {{$t('landingpage.productRecommendation')}}
    </p>

    <Cta/>
  </v-container>

</template>

<script>
import Cta from "@/components/LandingPage/Cta";
export default {
  name: "SectionList",
  components: {Cta},
  mounted() {
    let t = this;
    t.makeHighlight();
    setInterval(()=>{
      t.makeHighlight();
    },7000);
  },
  methods:{
    triggerMouseEvent (node, eventType) {
      let clickEvent = new MouseEvent(eventType, {});
      node.dispatchEvent (clickEvent);
    },
    simulateClick(node){
      this.triggerMouseEvent(node,"mouseover");
      this.triggerMouseEvent(node,"mousedown");
      this.triggerMouseEvent(node,"mouseup");
      this.triggerMouseEvent(node,"click");
    },
    removeItemOnce(arr, value) {
      let index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
    makeHighlight(){

      let t = this;
      for(let i=0;i<t.items.length;i++){
        setTimeout(()=>{
          let node = document.getElementById("item"+i);
          t.simulateClick(node);
        },300*i);
        setTimeout(()=>{
          t.selectedItems = t.removeItemOnce(t.selectedItems,i);
        },300*i+500);
      }
      setTimeout(()=>{
        t.selectedItems = [];
      },300*(t.items.length));
    }
  },
  data(){
    return{
      selectedItems:[],
      items:[
        {
          icon:"mdi-school",
          text: this.$t('landingpage.wantToLearnVerbs'),
          active:false
        },
        {
          icon:"mdi-cloud-question",
          text: this.$t('landingpage.problemWithVerbConjugation'),
          active:false
        },
        {
          icon:"mdi-folder-search-outline",
          text: this.$t('landingpage.seeVerbsInContext'),
          active:false
        },
        {
          icon:"mdi-arrow-expand-all",
          text: this.$t('landingpage.wantToExpandVocabulary'),
          active:false
        },
        {
          icon:"mdi-alert-circle-check-outline",
          text: this.$t('landingpage.knowBasicVerbs'),
          active:false
        },
        {
          icon:"mdi-clock-start",
          text: this.$t('landingpage.beginnerLookingForVerbs'),
          active:false
        },
        {
          icon:"mdi-account-school-outline",
          text: this.$t('landingpage.preparingForExams'),
          active:true
        },
        {
          icon:"mdi-eye-arrow-right",
          text: this.$t('landingpage.visualLearner'),
          active:false
        },
        {
          icon:"mdi-calendar-check-outline",
          text: this.$t('landingpage.likeSolvingTasks'),
          active:false
        }

      ],
    }
  },
}
</script>

<style >
.wrap-text {
  -webkit-line-clamp: unset !important;
  white-space: normal;
}
</style>