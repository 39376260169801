<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <p class="text-h6">
          {{$t('landingpage.faq1')}}
        </p>

        <v-expansion-panels focusable>
          <v-expansion-panel
              v-for="(item,i) in items"
              :key="i"
          >
            <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
            <v-expansion-panel-content v-html="item.answer" class="pa-4">
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Faq",
  data(){
    return{
      items:this.$t('landingpage.faqitems')
    }
  }
}
</script>

<style scoped>

</style>