<template>
  <v-container fluid>
    <v-row align="center"
           justify="center" >
      <v-card elevation="2" outlined class="mx-auto mt-2" max-width="900">
        <v-card-title>{{$t('flashcards.flashcardsTitle')}}</v-card-title>
        <v-card-text>
          <p>
            {{$t('flashcards.flashcardsDescription')}}
          </p>
          <div style="display:flex;justify-content: space-between; flex-wrap: wrap">
            <v-btn class="mb-2" color="success" @click="showInstruction=true">{{$t('flashcards.readInstructions')}}</v-btn>
            <v-btn class="mb-2" color="primary" @click="gotoFlashcards">{{$t('flashcards.startFlashcards')}} <v-icon small>mdi-arrow-right-bold-outline</v-icon></v-btn>
          </div>
          <h3>{{$t('flashcards.flashcardsSettings')}}</h3>
          <FlashcardsConfig></FlashcardsConfig>
          <div class="text-right">
            <v-btn class="ma-2" color="primary" @click="gotoFlashcards">{{$t('flashcards.startFlashcards')}} <v-icon small>mdi-arrow-right-bold-outline</v-icon></v-btn>
          </div>
          <FlashcardsInstruction v-model="showInstruction" />
        </v-card-text>
      </v-card>

    </v-row>
  </v-container>
</template>


<script>
import ConfigRadio from "@/components/Flashcards/ConfigRadio";
import FlashcardsConfig from "@/components/Flashcards/FlashcardsConfig";
import FlashcardsInstruction from "@/components/Flashcards/FlashcardsInstruction";
export default {
  name: "FlashcardsIntro",
  components: {FlashcardsInstruction, FlashcardsConfig, ConfigRadio},
  methods:{
    gotoFlashcards(){
      this.$emit("gotoflashcards");
    }
  },
  data(){
    return{
      showInstruction:false,
    }
  }
}
</script>

<style scoped>
.configInput{
  padding-top:0;
  margin-top:0;
}
</style>