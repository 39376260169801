<template>
  <v-container fill-height class="noflex">
    <div style="display: flex; flex-direction: column;height:100%;">

      <!--<Tasks id="z10_1" title="Obrazki 1"></Tasks>-->
      <div class="image-content">
        <v-carousel v-model="carousel" height="100%" hide-delimiters>
          <v-carousel-item v-for="(t,i) in tasks" :key="i"  >
            <v-sheet height="100%" width="100%" tile  >
              <v-row style="height: 100%;width: 100%;position: relative;display: flex;flex-direction: row;justify-content: center;align-items: center;margin:0px;" >
                <FImage :image-data="t" :visible="checkVisibility(i)" />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>


      </div>
      <div class="text-center" style="flex-basis: 40px;" v-if="!adVersion">
          <v-pagination v-model="carousel2" :length="tasks.length"></v-pagination>
      </div>

    </div>
    <MainTour tour-id="fromimages" v-if="!adVersion" />
  </v-container>
</template>

<script>
import Tasks from "@/components/Tasks/Tasks";
import FImage from "@/components/FromImages/FImage";
import imagemaps from "@/assets/imagemaps.json";
import imagemapsen from "@/assets/imagemapsen.json";
import MainTour from "@/components/Tours/MainTour";

export default {
  name: "FromImages",
  components: {MainTour, FImage, Tasks},
  props:{
    adVersion:Boolean
  },
  data(){
    if(this.$store.state.language==="en")
      imagemaps = imagemapsen;
    if(this.adVersion){
      imagemaps = [imagemaps[0]];
    }
    return {
      test:false,
      tasks:imagemaps,
      carousel:0,
      carousel2:1
    }
  },
  methods:{
    checkVisibility(index){
      return index===this.carousel;
    }
  },
  watch: {
    carousel(){
      this.carousel2 = this.carousel+1;
    },
    carousel2(){
      this.carousel = this.carousel2-1;
    }
  }
}
</script>

<style scoped>
.noflex{
  display:block;
}
.image-content{
  flex-grow: 1;
  max-height: calc(100vh - 64px - 24px - 45px);
}

</style>
<style>
@media screen and (max-width: 600px) {
  .image-content .v-window__prev, .image-content .v-window__next {
    top: calc(90% - 20px);
  }
}
</style>