<template>
  <v-app>
    <v-main>
      <v-container class="loginContainer">

         <!--<v-btn v-if="!hasDiscount" style="float:right" color="primary" @click="changeCurrency()">{{ $t('landingpage.currency')}}: {{currency}}</v-btn>-->
        <v-btn class="loginButton"  color="secondary" href="/Login">{{$t('landingpage.login')}}</v-btn>
      </v-container>
      <Top id="top" />
      <Section2/>
      <SectionImages id="inproduct" />
      <v-divider class="mt-8 mb-4"></v-divider>
      <SectionList id="forme"/>
      <Citation :text="$t('landingpage.cite')" author="Edmund de Waal" class="mt-8"/>
      <AboutMe id="aboutme" />
      <Icons class="pt-12"/>
      <Citation :text="$t('landingpage.cite2')" author="Nelson Mandela" />
      <Fragments class="mt-8" id="fragments"/>
      <Price class="mt-8" id="price" />
      <Faq id="faq" />
      <v-divider class="mt-8 mb-4"></v-divider>
      <BuySection/>
      <div style="height:30px;"></div>
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import Top from "@/components/LandingPage/Top";
import Section2 from "@/components/LandingPage/Section2";
import SectionImages from "@/components/LandingPage/SectionImages";
import SectionList from "@/components/LandingPage/SectionList";
import Citation from "@/components/LandingPage/Citation";
import AboutMe from "@/components/LandingPage/AboutMe";
import Icons from "@/components/LandingPage/Icons";
import Fragments from "@/components/LandingPage/Fragments";
import Faq from "@/components/LandingPage/Faq";
import BuySection from "@/components/LandingPage/BuySection";
import Price from "@/components/LandingPage/Price";
import Footer from "@/components/LandingPage/Footer";
import myprice from "@/plugins/myprice";
import verbsen from "@/assets/verbssampleen.json"

export default {
  name: 'Landingpage',

  components: {
    Footer,
    Price,
    BuySection,
    Faq,
    Fragments,
    Icons,
    AboutMe,
    Citation,
    SectionList,
    SectionImages,
    Section2,
    Top,
  },
  mounted() {

  },
  created() {
    this.$store.commit("setVerbs",verbsen)
    this.$store.commit("markAsAdvertisement");
  },
  computed:{
    currency(){
      return this.$store.state.currency;
    }
  },
  methods:{
    changeCurrency(){
      this.$store.commit('switchCurrency');
    },

  },
  data: () => ({
    //
  }),
  mixins:[myprice]
};
</script>
<style>
.currencyChoice .v-input__control{
  min-height: 36px!important;
}
</style>
<style scoped>

.loginButton{
  float:right;
}
.loginContainer{
  position:fixed;
  z-index:2;
  margin-top:0!important;
  padding-top:0!important;

}
</style>