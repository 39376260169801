<template>
  <v-container fluid fill-height class="justify-center full-width" >
    <!--<div style="height:500px;width:500px;">
  <h5p src="/h5p/t1" @xapi="handleXAPIEvent" :css="css"></h5p>
    </div>-->
    <div :id='"h5p-container-"+id' style="width:100%;height:75vh;max-width: 800px"  ></div>
  </v-container>
</template>

<script>
//import h5p from 'vue-h5p'
import { H5P as H5PStandalone } from 'h5p-standalone';
import axios from "axios";

export default {
  name: "Tasks",
  components: {

  },
  props:{
    id:String,
    title:String
  },
  computed: {
    css(){
      return `

      `;
    },
  },
  data(){
    return {
      xapiPreventionTimer: -1
    }
  },
  methods: {
    handleXAPIEvent (ev) {

      if((ev.getVerb()==="answered" && ev.getMaxScore()>1)||ev.getVerb()==="completed"){
        this.sendTaskData(ev.getScore());
      }
    },
    sendTaskData(score){
      this.$store.dispatch("setTaskData",{taskId:this.$store.state.h5pState.currentTask,score:score});
    },
    async mountH5p(){
      const el = document.getElementById('h5p-container-'+this.id);
      let postfix = "";
      if(this.$i18n.locale()!="pl")
        postfix = this.$i18n.locale();
      const options = {
        h5pJsonPath: '/h5p/tasks'+postfix+'/'+this.id,
        frameJs: '/h5p/core/frame.bundle.js',
        frameCss: '/h5p/core/h5p.css',
        librariesPath:'/h5p/libraries',
        id:this.id,
        customCss:'/h5p/core/custom.css',
        customJs:'/h5p/core/custom.js',
      };

      await new H5PStandalone(el, options);
      if(!this.$store.state.h5pState.xapiInitiated) {
        H5P.externalDispatcher.on("xAPI", this.handleXAPIEvent);
        this.$store.commit("setH5PXapiInitiated");
      }
    }
  },
  mounted() {
    this.mountH5p();
  }
}
</script>

<style>
iframe{
}
</style>