import config from "/public/config/config.json"
let plugin = {
    computed:{
        allCurrencies(){
            const ret = new Set();
            let currency = "";
            for(let l1 in config){
                for(let l2 in config[l1]){
                    for(let l3 in config[l1][l2]){
                        let r = config[l1][l2][l3]
                        currency = r['currency'];
                        ret.add(currency)
                    }

                }
            }
            let ret2 = [];
            ret.forEach(function(key,val){
                ret2.push(val)
            })
            return ret2;
        },
        myPriceRecord(){

            const records = config[this.$store.state.pricing][this.$i18n.locale()];
            for(let key in records){
                let r = records[key]
                if(r.currency === this.$store.state.currency)
                    return r
            }
        },
        myPrice(){
            return this.myPriceRecord.price;
        },
        myPriceWithTextPrefix(){
            if(this.myPriceRecord.product_code === "annual")
                return Math.ceil(this.myPriceRecord.price / 12)+".00";

            return this.myPrice;
        },
        myPriceWithTextSuffix() {
            if(this.$store.state.pricing === "annual")
                return this.$t("landingpage.total")+this.myPrice + " "+this.myPriceRecord.currency;
            return "";
        },
        myPriceWithCurrency(){
            let p = this.myPrice;
            p+=" "+this.myPriceRecord.currency;
            return p;
        },
        hasDiscount(){
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            if(params.discount==='elever'){
                return true;
            }
            return false;
        },
        finalPriceCode(){
            if(this.$store.state.currency==="PLN")
                return "standardPLN";
            if(this.hasDiscount)
                return "elever";
            return "standard";
        }
    },

}

export default plugin;