<template>

  <v-container class="topmain" fluid>
    <v-row class="text-center mb-12 toprow align-self-center">
      <v-col md="5" offset-md="1" col="5" offset="0" sm="7" offset-sm="0">

        <v-card class="mx-auto topcard">
          <v-card-text>
            <div>{{$t('landingpage.teachingOffer')}}</div>
            <p class="text-h3 text--primary">
              {{$t('landingpage.verbInvitation')}}
            </p>
            <div class="text-h5">
              {{$t('landingpage.comprehensiveProgram')}}
            </div>
            <Cta class="mt-8" />
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import Cta from "@/components/LandingPage/Cta";
import h5ptasks from "@/assets/h5ptasks.json";

export default {
  name: "Top",
  components: {Cta},
  data(){

    return{
    }
  }
}
</script>

<style scoped>

.topmain{
  display:flex;
  /* The image used */
  background-image: url("@/assets/images/bgpage.jpg");

  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

}
.topcard{
  background-color:rgba(255, 255, 255, 0.7);
}
@media screen and (max-width: 600px) {
  .topmain{
    background-position: 68%;
    height:80vh;
  }
  .topcard{
    margin-top:40vh;
    background-color:rgba(255, 255, 255, 0.8);
    width:100%;
  }
  .topcard .text-h3{
    font-size: 2rem!important;
  }
  .topcard .text-h5{
    font-size: 1.5rem!important;
  }

}
</style>