export default {
    "tasks":{
        steps: [
            {
                target: '#taskList .v-list-item',
                header: {title: 'Ćwiczenia',},
                content: `Tutaj możesz wybrać interesujące Cię ćwiczenia i utrwalać poznane czasowniki.`,
            },
            {
                target: '#taskList .v-list-item .taskListAction',
                targetHighlighted:'#taskList .v-list-item',
                header: {title: 'Postępy',},
                content: `Po prawej znajdziesz swoje aktualne postępy w ćwiczeniu.`,
            },
            {
                target: '#centerPoint',
                targetHighlighted: null,
                header: {title: 'Zapraszamy do nauki!',},
                content: `Dziękujemy za przejrzenie tej instrukcji! Miłej nauki!`
            },
        ]
    },
    "fromimages":{
        steps: [
            {
                target: 'div.vueTourCenter',
                targetHighlighted:'div.vueTourImage',
                header: {title: 'Nauka z obrazków',},
                content: `Tutaj możesz klikać na plusiki, żeby nauczyć się kolejnych czasowników.`,
            },
            {
                target: '#centerPoint',
                targetHighlighted: null,
                header: {title: 'Zapraszamy do nauki!',},
                content: `Dziękujemy za przejrzenie tej instrukcji! Miłej nauki!`
            },
        ]
    },
    "verbtable":{
        steps: [

            {
                target: '#tour-filters',
                header: {title: 'Filtry',},
                content: `Tutaj możesz wybrać jakie czasowniki chcesz przejrzeć oraz wyszukać konkretnych.`,
            },
            {
                target: '#row1',
                header: {title: 'Czasownik',},
                content: `W tabeli znajdziesz kolejne czasowniki. Znajdziesz tutaj czasownik, jego tłumaczenie oraz ikonki umożliwiające oznaczenie czasownika jako znany oraz jako ważny. Możesz kliknąć nutkę, żeby odtworzyć dźwięk lub kliknąć na czasownik, żeby zobaczyć szczegóły. Kliknijmy...`,
            },
            {
                target: '#modal-inner-1',
                header: {title: 'Informacje o czasowniku',},
                content: `Tutaj znajdziesz odmianę czasownika oraz przykłady użycia.`,
                params: {placement: 'top' },
                beforeAdditional:async function(resolve,nextStep,target){
                    document.querySelector("#row1").click();
                    setTimeout(function(){
                        nextStep(target);
                        resolve();
                    },800)

                }
            },
            {
                target: '#video-1',
                header: {title: 'Video',},
                content: `A na dole znajdziesz video - zostaniesz przeniesiony od razu do fragmentu dotyczącego tego czasownika.`,
                params: {placement: 'top' },
                beforeAdditional:async function(resolve,nextStep,target){
                    document.querySelector("#row1").click();
                    setTimeout(function(){
                        nextStep(target);
                        resolve();
                    },50)

                }
            },
            {
                target: '#close-modal-1',
                header: {title: 'Zamknij',},
                content: `Tutaj zamkniesz szczegóły i tą instrukcję.`,
                params: {placement: 'top' },
                beforeAdditional:async function(resolve,nextStep,element){
                    document.querySelector("#row1").click();
                    setTimeout(function(){
                        nextStep(element);
                        resolve();
                    },50)

                }
            },
            {
                target: '#centerPoint',
                targetHighlighted: null,
                header: {title: 'Zapraszamy do nauki!',},
                content: `Dziękujemy za przejrzenie tej instrukcji! Miłej nauki!`,
                beforeAdditional:async function(resolve,nextStep,target){
                    document.querySelector("#close-modal-1").click();
                    setTimeout(function(){
                        nextStep(target);
                        resolve();
                    },500)

                }
            },
        ]
    },
    "home":{
        steps: [
            {
                target: '#centerPoint',
                targetHighlighted: null,
                header: {title: 'Instrukcja',},
                content: `W tym miejscu znajdziesz instrukcje do wybranych sekcji appki.`
            },
            {
                target: '#centerPoint',
                targetHighlighted: null,
                header: {title: 'Zapraszamy do nauki!',},
                content: `Dziękujemy za przejrzenie tej instrukcji! Miłej nauki!`
            },
        ]
    },

}