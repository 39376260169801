<template>

  <v-btn-toggle v-model="model" tile
                color="deep-purple accent-3"
                class="flex-wrap mb-2"
                >
    <v-btn  :value="item.value" v-for="item in buttonValues" small>
      {{item.text}}
    </v-btn>
  </v-btn-toggle>

</template>

<script>
export default {
  name: "ConfigRadio",
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  props:{
    value:String,
    buttonValues:Array,
  },
}
</script>

<style scoped>

</style>