<template>

   <v-dialog
        transition="dialog-bottom-transition"
        max-width="900"
        v-model="show"
        content-class="dialogverbs"
    >

        <template v-slot:default="dialog">

          <v-card v-if="show">
            <v-toolbar
                color="primary"
                dark
            > {{ item.verb.word.text }} - {{ item.verb.word.translation }}</v-toolbar>
            <v-card-text :id="'modal-inner-'+seqNo">
              <h4 class="mb-1">Bøyning:</h4>
          <p > <AudioPlayer :text="item.verb.word.text" :audio-url="item.verb.word.audio"></AudioPlayer>
            - <AudioPlayer :text="item.verb.form1.text" :audio-url="item.verb.form1.audio"></AudioPlayer>
            - <AudioPlayer :text="item.verb.form2.text" :audio-url="item.verb.form2.audio"></AudioPlayer>
            - <AudioPlayer :text="item.verb.form3.text" :audio-url="item.verb.form3.audio"></AudioPlayer>
          </p>
              <h4 class="mb-1">Eksempler:</h4>
              <span v-for="sentence in item.sentences">
              <p><span style="color:black">  <AudioPlayer :text="sentence.text"  :audio-url="sentence.audio"></AudioPlayer></span><br/>
            <AudioPlayer :text="sentence.translation" :audio-url="sentence.audioTranslation"></AudioPlayer></p>

                </span>
              <div style="width:100%;height:300px" :id="'video-'+seqNo" >
                <VideoPlayer :start="item.timestamp" ref="player" v-if="show"  />
              </div>

            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="show = false" :id="'close-modal-'+seqNo">{{ $t("landingpage.closeButton") }}</v-btn>
            </v-card-actions>
          </v-card>

        </template>
      </v-dialog>

</template>

<script>
import AudioPlayer from "@/components/AudioPlayer";
import VideoPlayer from "@/components/VideoPlayer";
export default {
  name: "VerbWithModal",
  components: {VideoPlayer, AudioPlayer},
  props: {
    value: Boolean,
    initem:Object,
    seqNo:Number
  },
  data(){
    return{
      item: this.initem
    }
  },
  watch:{
    show:function(newValue, old){
      this.item = this.initem
      if(newValue)
        return;
    },
    '$store.state.videoTimer': function() {
      let timer = this.$store.state.videoTimer+0.5
      if(timer>0){
        let verb = null;
        for(let key in this.$store.state.verbs) {
          verb = this.$store.state.verbs[key]
          if (verb.timestamp>=timer) {
            verb = this.$store.state.verbs[key-1]
            break;
          }
        }

        if(verb.id === this.item.id)
          return;
        this.item = verb;
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style >
.dialogverbs{
  margin:0!important;
}
.dialogverbs .v-card__text{
  padding:12px!important;
}
</style>