import Vue from 'vue'
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    state:{
        tasks:{
            r_1: {
                "attempts": 5,
                "best_score": 11,
            },
            r_3: {
                "attempts": 3,
                "best_score": 15,
            }
        },
        updateTasksTimerPrevention:-1,
        overallScore:0
    },
    mutations:{

        setTasks(state,data){
            state.tasks = data;
        },
        setCurrentOverallScore(state) {
            let sum = 0;
            for(const key in state.tasks){
                sum+= state.tasks[key].best_score;
            }
            state.overallScore = sum;
        }
    },
    actions:{
        setTaskData({state},{taskId,score}){
            clearTimeout(state.updateTasksTimerPrevention);
            let t = this;
            state.updateTasksTimerPrevention = setTimeout(function(){
                t.dispatch("setTaskDataTimeout",{taskId:taskId,score:score});
            },300);
        },
        setTaskDataTimeout({state,getters},{taskId,score}){
            if(typeof state.tasks[taskId] === "undefined") {
                state.tasks[taskId] = {"attempts":0, "best_score":0};
            }
            let t = state.tasks[taskId];
            if(t.best_score<score){
                t.best_score = score;
            }
            t.attempts++;
            axios.post("/verbs/public/api/verbs/updateTaskData",{taskId:taskId,score:score,attempts:t.attempts});
            this.commit("setCurrentOverallScore");
        }
    },
    getters:{
        taskData:  (state) => (taskId) => {
            if(typeof state.tasks[taskId] === "undefined"){
                return {"attempts":0, "best_score":0}
            }
            return state.tasks[taskId];
        }
    }
};