<template>
  <v-dialog transition="dialog-bottom-transition" max-width="900" v-model="show" content-class="dialogverbs">
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>{{$t('flashcards.flashcardsInstruction')}}</v-toolbar>
        <v-card-text>
          <h4 class="mb-1">{{$t('flashcards.howToUseFlashcards')}}</h4>
          <v-expansion-panels readonly multiple v-model="panel">
            <v-expansion-panel v-for="(item,i) in items" :key="i">
              <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
              <v-expansion-panel-content v-html="item.answer" class="pa-4">
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="show = false">{{$t('flashcards.close')}}</v-btn>
        </v-card-actions>
      </v-card>


    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "FlashcardsInstruction",
  data() {
    return {
      panel:[0,1],
      items: this.$t("flashcards.instructionsItems")
    }
  },
  props: {
    value: Boolean,
    item:Object,
  },
  watch:{
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>