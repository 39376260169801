<template>
  <v-container class="s2" fluid>
    <v-container>
      <p>
        {{$t('landingpage.verbDescription')}}
        {{$t('landingpage.learningEase')}}
      </p>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Section2"
}
</script>

<style scoped>
.s2{
  background-color: #001253;
  font-size: 24pt;
  color: white;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .s2{
    font-size: 16pt;
    clear: both;
    margin-top: 0px;
  }
}
</style>