<template>
  <v-container class="s2" fluid>
    <v-container class="fontWhite">
      <p>{{$t('landingpage.accessPriceText')}} <b> <PriceCurrency></PriceCurrency></b></p>

      <Cta/>
    </v-container>

  </v-container>
</template>

<script>
import Countdown from "@/components/LandingPage/Countdown";
import Cta from "@/components/LandingPage/Cta";
import myprice from "@/plugins/myprice";
import PriceCurrency from "@/components/LandingPage/PriceCurrency";
export default {
  name: "Price",
  components: {PriceCurrency, Cta, Countdown},
  mixins:[myprice]
}
</script>

<style scoped>

.s2{
  background-color: #990000;
  font-size: 24pt;
  color: white;
  text-align: center;

}
@media screen and (max-width: 600px) {
  .s2{
    font-size: 16pt;
    clear: both;
    margin-top: 0px;
  }
}
</style>