<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <p class="text-h6">
          {{$t('landingpage.productFeatures')}}
        </p>
        <p>
          <v-list dense >
            <v-list-item-group color="primary" v-model="selectedItems" multiple>
              <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  ref="item2"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>

                  <v-list-item-subtitle v-html="item.text" class="wrap-text subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

        </p>
        <p>
          <Cta/>
        </p>
      </v-col>
    </v-row>
    <v-row class="mt-8 mb-4">
      <v-divider></v-divider>
    </v-row>
    <v-row id="videos">
      <v-col cols="12">
        <p class="text-h6">
          {{$t('landingpage.videoInstruction')}}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-6">
        <v-img :src="'/images/'+$i18n.locale()+'/obrazki1.jpg'" :lazy-src="'/images/'+$i18n.locale()+'/obrazki1.jpg'" max-height="600px"
               max-width="600px" class="sectionimg" @click="show = true" >
          <v-img :src="'/videos/'+$i18n.locale()+'/click.gif'"  class="clickimg" />
        </v-img>
      </v-col>
      <v-col class="col-sm-6">
        <v-img :src="'/images/'+$i18n.locale()+'/obrazki2.jpg'" :lazy-src="'/images/'+$i18n.locale()+'/obrazki2.jpg'" max-height="600px"
               max-width="600px" class="sectionimg2" @click="show2 = true" >
          <v-img :src="'/videos/'+$i18n.locale()+'/click.gif'"  class="clickimg2" />
        </v-img>
      </v-col>
    </v-row>

    <v-dialog
        transition="dialog-bottom-transition"
        max-width="1200"
        v-model="show"
        content-class="dialogverbs"
    >

      <template v-slot:default="dialog">
        <v-card>
          <v-card-text>
            <video autoplay loop muted  width="100%" height="100%">
              <source :src="video(1,'mobile')" v-if="isMobile">
              <source :src="video(1,'standard')" v-else>
            </video>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                text
                @click="show = false"
            >{{$t('landingpage.closeButton')}}</v-btn>
          </v-card-actions>
        </v-card>

      </template>
    </v-dialog>

    <v-dialog
        transition="dialog-bottom-transition"
        max-width="1200"
        v-model="show2"
        content-class="dialogverbs"
    >

      <template v-slot:default="dialog">
        <v-card>
          <v-card-text>
            <video autoplay loop muted  width="100%" height="100%">
              <source :src="video(2,'mobile')" v-if="isMobile">
              <source :src="video(2,'standard')" v-else>
            </video>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                text
                @click="show2 = false"
            >{{$t('landingpage.closeButton')}}</v-btn>
          </v-card-actions>
        </v-card>

      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import ismobile from  "@/plugins/ismobile";
import Vue from "vue";
import Cta from "@/components/LandingPage/Cta";
export default {
  name: "SectionImages",
  components: {Cta},
  methods:{
    video(index,version){
      let link = "/videos/"+this.$i18n.locale();
      if(index===1)
        link+="/tabelkavideo";
      else
        link+="/resztavideo";
      if(version==="mobile")
        link+="mob";
      link+=".mp4";
      return link;
    }
  },
  data(){

    return {
      show:false,
      show2:false,
      selectedItems:[],
      items:[
        {
          icon:"mdi-school",
          text: this.$t('landingpage.interactiveTableIntro') + this.$store.state.stats.verbsNo + this.$t('landingpage.interactiveTableOutro'),
          active:false
        },
        {
          icon:"mdi-cloud-question",
          text: this.$t('landingpage.exampleSentencesIntro') + (this.$store.state.stats.verbsNo*2) + this.$t('landingpage.exampleSentencesOutro'),
          active:false
        },
        {
          icon:"mdi-folder-search-outline",
          text: this.$t('landingpage.norwegianRecordings'),
          active:false
        },
        {
          icon:"mdi-arrow-expand-all",
          text: this.$t('landingpage.polishRecordings'),
          active:false
        },
        {
          icon:"mdi-alert-circle-check-outline",
          text: this.$t('landingpage.verbVideoExamples'),
          active:false
        },
        {
          icon:"mdi-clock-start",
          text: this.$t('landingpage.interactiveTasksIntro') + this.$store.state.stats.tasksNo + this.$t('landingpage.interactiveTasksOutro') + this.$store.state.stats.subtasksNo + this.$t('landingpage.interactiveTasksOutro2'),
          active:false
        },
        {
          icon:"mdi-account-school-outline",
          text: this.$t('landingpage.flashcards'),
          active:true
        },
        {
          icon:"fa-regular fa-image",
          text: this.$t('landingpage.learningFromImages'),
          active:true
        }


      ],
    }
  },
  created() {
    Vue.use(ismobile);
  },
  mixins:[ismobile],
}
</script>
<style>
.subtitle{
  color:rgba(0, 0, 0, 0.87)!important;
  font-size: 0.9rem!important;
  line-height: 1.2rem!important;
}
</style>
<style scoped>

.sectionimg{
  float:right;
  cursor: pointer;
  margin-left:12px;
  width: 100%;
}
.sectionimg2{
  float:left;
  cursor: pointer;
  margin-right:12px;
  width: 100%;
}
.clickimg{
  position: absolute;
  left:20%;
  bottom:70px;
  width:50%;
  height:50%;
}
.clickimg2{
  position: absolute;
  left:20%;
  bottom:30%;
  width:50%;
  height:50%;
}
@media screen and (max-width: 960px) {
  .sectionimg{
    float:none;
    margin: auto;
  }
  .sectionimg2{
    float:none;
    margin: auto;
  }
}
</style>