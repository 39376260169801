<template>
  <div style="height:100%;">
    <video ref="videoPlayer" class="video-js" ></video>
  </div>
</template>

<script>
import videojs from 'video.js';
require('@silvermine/videojs-quality-selector/dist/css/quality-selector.css')
require('videojs-sprite-thumbnails');

export default {
  name: "VideoPlayer",
  props:{
    start:Number
  },
  data() {
    let language = this.$i18n.locale()
    let sample = this.$store.state.isAdvertisement;
    let sources = [
      {
        src: 'https://speaknorwegianpublic.s3.eu-north-1.amazonaws.com/verbs/video/main'+language+'/output360.mp4',
        type: 'video/mp4',
        label: "360P"
      },
      {
        src: 'https://speaknorwegianpublic.s3.eu-north-1.amazonaws.com/verbs/video/main'+language+'/output720.mp4',
        type: 'video/mp4',
        label: "720P"
      },
      {
        src: 'https://speaknorwegianpublic.s3.eu-north-1.amazonaws.com/verbs/video/main'+language+'/output1080.mp4',
        type: 'video/mp4',
        label: "1080P",
        selected:true
      },
    ];
    if(sample){
      sources = [
        {
          src: require("@/assets/video"+language+"/sample.mp4"),
          type: 'video/mp4',
          label: "720P"
        }
      ];
    }
    return {
      player: null,
      timer: null,
      sample: sample,
      videoOptions: {
        autoplay: false,
        controls: true,
        poster:require("@/assets/images/videoposter"+language+".jpg"),
        controlBar:{
          children: [
            'playToggle',
            'progressControl',
            'volumePanel',
            'qualitySelector',
            'fullscreenToggle',
          ],
        },
        sources: sources
      }
    }
  },
  methods:{
    selfDestroy(){
      this.$destroy();
    }
  },
  mounted() {
    let language = this.$i18n.locale()
    let interval = 32
    if(language=="en")
      interval = 45
    require("@silvermine/videojs-quality-selector")(videojs);
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions, () => {
      this.player.log('onPlayerReady', this);
    });
    if(this.start>0) {
      this.player.currentTime(this.start);
    }
    if(this.sample)
      return;
    this.player.spriteThumbnails({
      interval: interval,
      url: require("@/assets/images/videosprites"+language+".jpg"),
      width: 240,
      height: 135
    });
    let t=this;
    this.timer = setInterval(function(){
      if(t.player.currentTime()<1)
        return;
      t.$store.commit("setVideoTimer",t.player.currentTime())

    },1000);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
    clearInterval(this.timer)
  }
}
</script>

<style>
.video-js{
  height:100%;
}
</style>