<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <p class="text-h6">
          {{$t('landingpage.aboutme1')}}
        </p>
        <v-img src="@/assets/images/natalka.jpg" lazy-src="@/assets/images/natalka.jpg" max-height="600px"
               max-width="600px" class="sectionimg" @click="show = true" >
        </v-img>



        <p>
          {{$t('landingpage.aboutme2')}}
        </p>
        <p>{{$t('landingpage.aboutme3')}}

          </p>
        <p>
          {{$t('landingpage.aboutme4')}}
          </p>
        <p>
          {{$t('landingpage.aboutme5')}}
        </p>
        <p>
          <Cta/>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Cta from "@/components/LandingPage/Cta";
export default {
  name: "AboutMe",
  components: {Cta}
}
</script>

<style scoped>
.sectionimg{
  float:right;
  cursor: pointer;
  margin-left:12px;
}

@media screen and (max-width: 960px) {
  .sectionimg{
    float:none;
    margin: auto;
  }
}
</style>