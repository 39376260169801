<template>
  <div>
    <MainTour tour-id="verbtable" />
    <v-card style="width: 100%;">
      <v-card-title id="">
        Tabelka czasowników
      </v-card-title>
      <v-container id="">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6" >
            <label id="filters">Jakie czasowniki pokazać?</label><br/>
            <ConfigRadio :value="verbType" field="verbType"  @input="updateFields('verbType',$event)" :button-values="verbTypes" ></ConfigRadio>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Poszukaj czasowników"
                single-line
                hide-details
                id="v-step-1"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-simple-table dense >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Verb
            </th>
            <th class="text-left">
              Oversettelse
            </th>
            <th></th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <tr
              v-for="(item,i) in verbs"
              :key="item.id"
              @click="showDialog(item.id)"
              v-show="!hidden[item.id]"
          >
            <td>
              <v-lazy v-model="item.isActive">
                <span :id="'row'+i">
                  <v-icon>mdi-chevron-down</v-icon>
                  {{ item.verb.word.text }}
                  <AudioPlayer :audio-url="item.verb.word.audio"></AudioPlayer>
                  <VerbWithModal :item="item" v-model="dialogs[item.id]" :seq-no="i"  ></VerbWithModal>
                </span>
              </v-lazy>
            </td>
            <td>
              <v-lazy v-model="item.isActive">
                <span>
                <WordCutter :word="item.verb.word.translation"></WordCutter>
                <AudioPlayer :audio-url="item.verb.word.audioTranslation"></AudioPlayer>
                </span>
              </v-lazy>
            </td>
            <td>
              <v-lazy v-model="item.isActive">
                <StateChanger :id="item.id" type="know" :current-state="item.know" ></StateChanger>
              </v-lazy>
            </td>
            <td>
              <v-lazy v-model="item.isActive">
                <StateChanger :id="item.id" type="important" :current-state="item.important"></StateChanger>
              </v-lazy>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>


    </v-card>

  </div>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer";
import StateChanger from "@/components/StateChanger";
import WordCutter from "@/components/WordCutter";
import VerbWithModal from "@/components/VerbWithModal";
import {checkMatch} from "@/functions/string";
import ConfigRadio from "@/components/Flashcards/ConfigRadio";
import MainTour from "@/components/Tours/MainTour";

export default {
  name: "VerbTable2",
  components: {MainTour, ConfigRadio, VerbWithModal, WordCutter, StateChanger, AudioPlayer},
  methods:{
    showDialog(itemId){
      this.$set(this.dialogs,itemId,true);
    },
    updateFields(name,val){
      this[name] = val;
      this.searchVerbs();
    },
    searchVerbs(){
      clearTimeout(this.timeoutSearch);
      let searchPhrase = this.search;
      let tmpthis = this;
      this.timeoutSearch = setTimeout(function(){
        tmpthis.verbs.forEach(function(el,index){
          let hideAfter = false;

          if((tmpthis.verbType ==="onlyUnknown" && el.know===1)
              || (tmpthis.verbType ==="onlyKnown" && el.know===0)
              || (tmpthis.verbType ==="onlyImportant" && el.important===0))
            hideAfter = true;
          if(!checkMatch(searchPhrase,el.verb.word.text+" "+el.verb.word.translation+" "+el.verb.form1.text+" "+el.verb.form2.text+" "+el.verb.form3.text))
            hideAfter = true;
          tmpthis.$set(tmpthis.hidden, el.id, hideAfter);

        });
      },200);
    }
  },
  watch:{
    search(){
      this.searchVerbs();
    }
  },
  created() {
    this.$store.commit("tourAvailable",true);
    this.$store.commit("endTour");
  },
  destroyed() {
    this.$store.commit("tourAvailable",false);
  },
  data(){
    let verbs = this.$store.state.verbs;
    let dialogs = [];
    let hidden = [];
    verbs.forEach(function(item){
      item.isActive = false;
      item.isHidden = false;
      dialogs[item.id] = false;
      hidden[item.id] = false;
    });
    return{
      dialogs:dialogs,
      timeoutSearch:-1,
      search: '',
      verbs:verbs,
      hidden:hidden,
      verbType:"all",
      verbTypes:[{text:"Wszystkie",value:"all"},{text:"Tylko nieznane",value:"onlyUnknown"},{text:"Tylko znane",value:"onlyKnown"},{text:"Tylko ważne",value:"onlyImportant"}],
    }
  }
}
</script>

<style scoped>
td:nth-child(3), td:nth-child(4),th:nth-child(3), th:nth-child(4){
  padding:0!important;
  width:1px;
}
td:nth-child(1), td:nth-child(2){
  padding: 0px 0px 0px 6px!important;
}
td{
  cursor: pointer;
}
</style>