
function convertSigns(str){
    str = str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
    str = str.toLowerCase();
    str = str.replace(/ø/gi,"o");
    str = str.replace(/æ/gi,"a");
    str = str.replace(/ł/gi,"l");
    str = str.replace(/\s/gi,"");
    return str;
}

export function checkMatch(search,searchable){
    search = convertSigns(search);
    searchable = convertSigns(searchable);
    return searchable.search(search)>-1;
}