import Vue from 'vue';
import '@fortawesome/fontawesome-free/css/all.css'
import Vuetify from 'vuetify/lib/framework';
import { Framework } from 'vuetify'


Vue.use(Vuetify);

export default new Vuetify({
});
