import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from "vue-router";
import router from './router';
import Vuex from 'vuex';
import State from './state/state';
//import VueI18n from 'vue-i18n'

import axios from "axios";
import {StripePlugin} from "@vue-stripe/vue-stripe";
import VueTour from "vue-tour/src/main";
import translate from './locales/translate.json'
import vuexI18n from "vuex-i18n";

require('vue-tour/dist/vue-tour.css')
console.log(translate)


Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueTour);

var token = State.state.persistent.token;

const options = {
  pk: "pk_test_51IDVxACIAG2mwo6L8ZpzcWWBRPQQZB4BPBjvlO5LHmRU3zeDTxubf8ZO4mQNsPBuCqJbvbd2rSrxyZkvkNlIyJ8U00lhz3VVc3",
  testMode: true, // Boolean. To override the insecure host warning
};

Vue.use(StripePlugin, options);
//Vue.use(VueI18n);
/*const i18n = new VueI18n({
  locale:'en',
  messages:translate
})*/
Vue.use(vuexI18n.plugin,State);
Vue.i18n.add("en",require("./locales/en.json"));
Vue.i18n.add("pl",require("./locales/pl.json"));

const urlParams = new URLSearchParams(window.location.search);
const language = urlParams.get('language');
if(["pl","en"].includes(language))
  Vue.i18n.set(language);
else if (location.hostname == "verb.nataliapasternak.pl" || location.hostname == "localhost"){
  Vue.i18n.set("pl");
}else{
  Vue.i18n.set("en");
}
axios.interceptors.request.use(function(config){
  config.baseURL="https://nataliapasternak.pl/";
  config.headers.Authorization =  "Bearer "+token;
  config.headers.myLanguage = Vue.i18n.locale();
  return config;
});


new Vue({
  //i18n,
  router,
  vuetify,
  store:State,
  render: h => h(App)
}).$mount('#app')
