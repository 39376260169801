<template>
  <v-app id="inspire">

    <button @click="submit">Pay now!</button>
  </v-app>
</template>

<script>
import { StripePlugin } from '@vue-stripe/vue-stripe';
export default {
  components: {

  },
  data () {
    return {

    };
  },
  mounted() {

  },
  methods: {
    submit () {
      window.location = "https://nataliapasternak.pl/verbs/public/api/product/pay";
    },
  },
  name: "Register"
}
</script>

<style scoped>

</style>