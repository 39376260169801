<template>
  <router-view></router-view>
</template>

<script>
import Login from "@/components/Registration/Login";
export default {
  name: "RegistrationPanel",
  components: {Login}
}
</script>

<style scoped>

</style>