<template>
  <span @click="playAudio($event,audioUrl)">
    {{text}}
    <v-btn icon  x-small>

    <v-icon>mdi-music-note-eighth</v-icon>
  </v-btn>
  </span>
</template>

<script>
export default {
  name: "AudioPlayer",
  props:{
    audioUrl:String,
    text:String
  },
  methods:{
    playAudio(event,audioUrl){

      let audio = new Audio(audioUrl); // path to file
      audio.play();
      this.$store.commit("setCurrentAudio",audio);
      event.stopPropagation();
    }
  }
}
</script>

<style scoped>
  span{
    cursor: pointer;
  }
</style>