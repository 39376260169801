<template>

  <v-container class="pa-0">
    <div class="mt-6">
        <p class="text-h6">{{$t('landingpage.fragments1')}}</p>
    </div>
    <div class="arrows"></div>
  <v-card class="elevation-10">
    <v-tabs id="maintab" v-model="tab" background-color="deep-purple accent-4" centered dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        {{$t('landingpage.tableLabel')}}
        <v-icon>mdi-table</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        {{$t('landingpage.videoLabel')}}
        <v-icon>mdi-video</v-icon>
      </v-tab>

      <v-tab href="#tab-3">
        {{$t('landingpage.tasksLabel')}}
        <v-icon>fa-solid fa-list-check</v-icon>
      </v-tab>

      <v-tab href="#tab-4">
        {{$t('landingpage.cardsLabel')}}
        <v-icon>fa-solid fa-file-image</v-icon>
      </v-tab>
    </v-tabs>


    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text class="ma-0 pa-0">
            <VerbTable2/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-card flat>
          <v-card-text>
            <VideoPlayer style="height:500px;"></VideoPlayer>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-card flat>
          <v-card-text>
            <TasksMenu  />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-4">
        <v-card flat>
          <v-card-text >
            <Flashcards v-if="status==='game'" @endGame="gotoResult" />
            <FlashcardsResult v-else-if="status==='result'" :known-cards="knownCards" :cards-no="cardsNo"></FlashcardsResult>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
  </v-container>
</template>

<script>
import VerbTable2 from "@/components/VerbTable2";
import TasksMenu from "@/components/Tasks/TasksMenu";
import 'video.js/dist/video-js.css'
import Flashcards from "@/components/Flashcards/Flashcards";
import FlashcardsResult from "@/components/Flashcards/FlashcardsResult";
import VideoPlayer from "@/components/VideoPlayer";

export default {
  name: "Fragments",
  components: {VideoPlayer, FlashcardsResult, Flashcards, TasksMenu, VerbTable2},
  data(){
    return{
      status:'game',
      cardsNo:0,
      knownCards:0,
      tab:"tab-1",
        playerOptions: {
          // videojs options
          muted: true,
          language: 'en',
          sources: [{
            type: "video/mp4",
            src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
          }],
          poster: "/static/images/author.jpg",
        }
      }
  },
  methods:{
    gotoResult(known,all){
      this.cardsNo = all;
      this.knownCards = known;
      this.status = "result";
    },
  }
}
</script>
<style scoped>
.text-header{
  font-size:16pt;
}
.arrows{
  margin:auto;
  height:200px;
  background-image:  url("@/assets/images/arrows2.png");
  background-position: top center;
  filter: brightness(0.3) invert(.7) sepia(.5) hue-rotate(180deg) saturate(200%);
  animation: filter-animation 2s infinite;
}
#maintab{
  filter: brightness(0);
  animation: filter2 2s infinite;
  animation-delay: 500ms;
}
@keyframes filter2 {
  0% {
    filter: brightness(1.0);
  }
  49% {
    filter: brightness(1.0);
  }
  50% {
    filter: brightness(1.3);
  }
  100% {
    filter: brightness(1.0);
  }
}
@keyframes filter-animation {
  0% {
    filter: brightness(0.1) invert(.3) sepia(.5) hue-rotate(180deg) saturate(200%);
  }
  49% {
    filter: brightness(0.1) invert(.3) sepia(.5) hue-rotate(180deg) saturate(200%);
  }
  50% {
    filter: brightness(0.9) invert(.7) sepia(.5) hue-rotate(100deg) saturate(200%);
  }

  100% {
    filter: brightness(0.1) invert(.3) sepia(.5) hue-rotate(180deg) saturate(200%);
  }
}
/*
.arrows{
  max-height: 200px;
  max-width: 1200px;
  background-image:  url("@/assets/images/arrows.png");
  background-position: top center;
  background-size: cover;
  aspect-ratio: 1200/200;
}
.flipped{
  transform: rotate(180deg);
  margin-top:10px;
}*/
</style>
<style>
.video-js{
  width:100%;
}
.video-js .vjs-big-play-button{
  top:45%;
  left:45%;
}
</style>