import { render, staticRenderFns } from "./FlashcardsInstruction.vue?vue&type=template&id=358427c5&scoped=true&"
import script from "./FlashcardsInstruction.vue?vue&type=script&lang=js&"
export * from "./FlashcardsInstruction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "358427c5",
  null
  
)

export default component.exports