<template>
  <v-menu offset-y style="max-width: 600px" open-on-hover>

    <template v-slot:activator="{on, attrs   }">
      <v-btn icon elevation="4" color="white"  class="imgElementBtn" v-bind="attrs" v-on="on">
        <v-icon class="imgElementIcon">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <span v-html="text"></span>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "Tooltip",
  props:{
    verbId:Number,
    text:String
  },
  methods:{

  },
  data(){
    return{

    }
  }
}
</script>

<style scoped>
.imgElementBtn{
  position: relative;
  top:-18px;
  left:-18px;
  height:36px;
  width:36px;
  opacity: 1;
  background-color: #e91e63;
  color: white;
}
@media screen and (max-width: 600px), screen and (max-height:600px) {
  .imgElementBtn {
    height: 18px;
    width: 18px;
    top:-9px;
    left:-9px;

  }
  .imgElementIcon{
    font-size:18px!important;
    width:18px!important;
    height:18px!important;
  }
}
.imgElementBtn:hover{
  background-color: #f92e73;
}

</style>