<template>
  <v-container >
    <v-row>
      <v-col cols="12">
      <p class="text-h6">
        {{$t('landingpage.buy1')}}
      </p>
      <p>
        {{$t('landingpage.buy2')}}

      </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
      <ul v-html="$t('landingpage.buyli',{verbsNo: $store.state.stats.verbsNo,subtasksNo:$store.state.stats.subtasksNo, tasksNo:$store.state.stats.tasksNo })">

      </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
      <p>
          <b>{{$t('landingpage.ctabuy4')}} <PriceCurrency/></b>
      </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
    <Cta/>
    <v-divider class="mt-8"></v-divider>
      </v-col>
    </v-row>
      <v-row>
      <v-col cols="12">
      <p class="text-h6 mt-8">
        {{$t('landingpage.buyquestions')}}
      </p>
      <p v-html="$t('landingpage.buycontact')"></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Cta from "@/components/LandingPage/Cta";
import PriceNow from "@/components/LandingPage/PriceNow";
import PriceCurrency from "@/components/LandingPage/PriceCurrency";
export default {
  name: "BuySection",
  components: {PriceCurrency, PriceNow, Cta}
}
</script>

<style scoped>

</style>