<template>
  <Landingpage v-if="status==='landingpage'"></Landingpage>
  <PaymentVerify v-else-if="status==='paymentverify'"></PaymentVerify>
  <Loader v-else-if="status==='loading'"></Loader>
  <RegistrationPanel v-else-if="status==='login'" />
  <Main v-else />
</template>

<script>
import HelloWorld from './components/HelloWorld';
import verbList from './assets/list.tsv';
import Login from "@/components/Registration/Login";
import Loader from "@/components/Loader";
import axios from "axios";
import Register from "@/components/Registration/Register";
import Main from "@/components/Main";
import RegistrationPanel from "@/components/Registration/RegistrationPanel";
import Landingpage from "@/components/LandingPage/Landingpage";
import PaymentVerify from "@/components/PaymentVerify";
import h5ptasks from "@/assets/h5ptasks.json";

export default {
  name: 'App',

  components: {
    PaymentVerify,
    Landingpage,
    RegistrationPanel,
    Main,
    Register,
    Loader,
    Login,
    HelloWorld,
  },

  methods:{
    readVerbApi(data){
      this.$store.commit("setVerbs",data.data.verbs)
      this.$store.commit("setFlashcards",data.data.config.flashcards);
      this.$store.commit("setTasks",data.data.taskData);
      this.$store.commit("setSubscriptionTo",data.data.subscriptionDate);
    },
    gotoLogin(){
      this.status="login";
      if(this.$router.currentRoute.path!=="/Login" )
        this.$router.push("/Login");
    },
    fillstats(){
      let stats = this.$store.state.stats;
      stats.tasksNo = 0;
      stats.subtasksNo=0;
      h5ptasks.forEach(function(item){
        if(item.category==="rek")
          return;
        stats.subtasksNo+=item.maxScore;
        stats.tasksNo++;
      });
      this.$store.commit("setStats",stats);
    }
  },
  mounted(){
    this.$store.commit("init");
    this.fillstats();
    if(this.$router.currentRoute.path==="/paymentverify"){
      return this.status = "paymentverify";
    }
    if(this.$router.currentRoute.path==="/landingpage"){
      return this.status = "landingpage";
    }
    if(!this.$store.state.persistent.token.length>0){
      if(this.$router.currentRoute.path==="/"){
        return this.status = "landingpage";
      }
      if(this.$router.currentRoute.path==="/register"){
        return this.status="login";
      }
    }
    let token = this.$store.state.token;
    let resp = axios.get("/verbs/public/api/user/checklogged");
    var t = this;
    resp.then(function(response){
      if(response.data.loggedIn){
        axios.get("/verbs/public/api/verbs").then(function(response){
          t.readVerbApi(response.data);
          t.status="app";
          console.log(t.$router.currentRoute.path);
          if(["/Login","/"].includes(t.$router.currentRoute.path))
            t.$router.push("/home");
        }).catch(function(response){
          console.log(response)
          t.gotoLogin();
        });
        return;
      }
      t.gotoLogin();
    }).catch(function(response){
      t.gotoLogin();
    });
  },

  data: () => {
    return {
      status:'loading',
    }
  },

};
</script>
<style>
.subActive{
  background-color: transparent;
}
</style>