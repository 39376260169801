<template>
  <v-tooltip top v-if="this.currentState == 0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon @click="switchState($event)" v-bind="attrs" v-on="on">
        <v-icon color="#CCC" >{{ icon1 }}</v-icon>
      </v-btn>
    </template>
    <span>{{ text1 }}</span>
  </v-tooltip>
  <v-tooltip top v-else>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon @click="switchState($event)"  v-bind="attrs" v-on="on">
        <v-icon :color="color">{{ icon2 }}</v-icon>
      </v-btn>
    </template>
    <span>{{ text2 }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "StateChanger",
  props:{
    id:Number,
    type:String,
    currentState:Number
  },
  data:function(){
    if(this.type=="important"){
      return{
        icon1:"mdi-exclamation",
        icon2:"mdi-exclamation-thick",
        text1:this.$t('stateChanger.markImportant'),
        text2:this.$t('stateChanger.markedImportant'),
        color:"red"
      }
    }
    return{
      icon1:"mdi-check-outline",
      icon2:"mdi-check-bold",
      text1:this.$t('stateChanger.markKnown'),
      text2:this.$t('stateChanger.markedKnown'),
      color:"green"
    }
  },
  methods:{
    switchState(event){
      event.stopPropagation();
      this.$store.dispatch("switchVerbStatus",{id:this.id,type:this.type,changeTo:!this.currentState});
      this.$store.commit("setVerbStatus",{id:this.id,type:this.type,changeTo:!this.currentState});
      if(this.currentState==1)
        return this.currentState = 0;
      this.currentState = 1;
    }
  }
}
</script>

<style scoped>

</style>