<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{$t('paymentVerify.checkingPayment')}}</v-toolbar-title>
              </v-toolbar>
              <v-card-text style="text-align: center;" class="pt-8 pb-4">
                <p v-if="status==='wait'">
                  <v-alert color="info" dark icon="fa-solid fa-hourglass-half" prominent style="font-size:24px;">
                    {{$t('paymentVerify.paymentBeingProcessed')}}
                  </v-alert>
                </p>
                <p v-else-if="status==='complete'">
                  <v-alert color="success" dark icon="fa-regular fa-thumbs-up" prominent style="font-size:24px;">
                    {{$t('paymentVerify.paymentProcessed')}}
                  </v-alert>
                  {{$t('paymentVerify.paymentProcessedDetails')}} <a href="mailto:pomoc@nataliapasternak.pl">pomoc@nataliapasternak.pl</a><br/><br/>
                  <v-btn class="success" x-large @click="loginToken">{{$t('paymentVerify.loginViaToken')}}</v-btn>
                </p>
                <p v-else-if="status==='error'">
                  <v-alert color="error" dark icon="fa-solid fa-triangle-exclamation" prominent style="font-size:24px;">
                    {{$t('paymentVerify.paymentFailed')}}
                  </v-alert>
                  {{$t('paymentVerify.retryPayment')}} <a href="https://verb.nataliapasternak.pl/landingpage">{{$t('paymentVerify.returnToOffer')}}</a>.
                  {{$t('paymentVerify.contactUsFailed')}} <a href="mailto:pomoc@nataliapasternak.pl">pomoc@nataliapasternak.pl</a>.
                </p>
                <p v-else-if="status==='fatal'">
                  <v-alert color="error" dark icon="fa-solid fa-triangle-exclamation" prominent style="font-size:24px;">
                    {{$t('paymentVerify.unknownError')}}
                  </v-alert>
                  {{$t('paymentVerify.serverPaymentError')}} <a href="mailto:pomoc@nataliapasternak.pl">pomoc@nataliapasternak.pl</a>.
                </p>
                <p v-else-if="status==='timeout'">
                  <v-alert color="primary" dark icon="fa-regular fa-clock" prominent style="font-size:24px;">
                    {{$t('paymentVerify.paymentNotProcessed')}}
                  </v-alert>
                  {{$t('paymentVerify.contactUsTimeout')}} <a href="mailto:pomoc@nataliapasternak.pl">pomoc@nataliapasternak.pl</a>.<br/><br/>
                  {{$t('paymentVerify.checkPaymentStatus')}} <v-btn class="primary" x-small @click="checkPaymentManual">{{$t('paymentVerify.checkStatus')}}</v-btn>
                </p>
              </v-card-text>
              <v-footer class="pa-0">
                <v-progress-linear color="light-blue" height="10" :value="progressValue" striped></v-progress-linear>
              </v-footer>
            </v-card>


          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "PaymentVerify",
  data(){
    return{
      progressValue:0,
      intervalId:-1,
      status:"wait",
      checked:0,
      email:"",
    }
  },
  methods:{
    loginToken(){
      window.location = "/Login?email="+this.email;
    },
    checkPaymentManual(){
      this.status = "wait";
      let t =this;
      t.progressValue=10;
      setTimeout(function(){
        t.progressValue=50;
        t.checkPayment();
      },2000);
    },
    checkPayment(){
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let resp = axios.get("/verbs/public/api/payment/check?checkoutsession="+params.checkoutsession);
      let t=this;
      resp.then(function(response){
        t.checked++;
        if((response.data.status==="started" || response.data.status==="open") && t.checked<4){
          setTimeout(function(){
            t.checkPayment();
          },5000);
        }else if(response.data.status === "complete"){
          t.progressValue = 100;
          t.email = response.data.email;
          t.status = "complete";
        }else if(response.data.status === "expired"){
          t.progressValue = 100;
          t.status = "error";
        }else if((response.data.status==="started" || response.data.status==="open")){
          t.progressValue = 100;
          t.status = "timeout";
        }else{
          t.progressValue = 100;
          t.status = "fatal";
        }
      });
      resp.catch(function(response){
        t.progressValue = 100;
        t.status = "fatal";
      });
    }
  },
  mounted() {
    let t = this;
    setTimeout(function(){
      t.progressValue +=10;
      t.checkPayment();
    },1000);
    this.intervalId = setInterval(function(){
      if(t.progressValue>5)
        t.progressValue ++;
      if(t.progressValue>90){
        clearInterval(t.intervalId);
      }
    },1000);
  }
}
</script>

<style scoped>

</style>