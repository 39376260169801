<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-progress-linear :value="progressOverall" color="blue" height="25" v-bind="attrs" v-on="on">
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </template>
      <span>{{ $t('tasks.youAchieved') }} {{progressOverall}}% {{ $t('tasks.pointsPossible') }} ({{$store.state.moduleTasks.overallScore}}/{{$store.state.stats.subtasksNo}})!</span>
    </v-tooltip>

    <v-container fluid id="taskList">
      <h1>{{$t('tasks.exercisesHeader')}}</h1>
      <v-list>
        <v-subheader>{{$t('tasks.chooseExercise')}}</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in h5ptasks" :key="i" v-if="item.category === category" @click="activateDialog(i)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="item.maxScore == taskData(item.taskId).best_score" color="green">mdi-check-bold</v-icon>
                {{item.title}}

                <v-chip class="ml-2 mr-2">
                  {{$t('tasks.attempts')}}: {{taskData(item.taskId).attempts}}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="taskListAction">
              <div>
                <v-progress-circular :rotate="360" :size="50" :width="4" :value="progress(i,item.taskId)" :color="progress(i,item.taskId)>99.99?'green':'pink'">
                  {{ progress(i,item.taskId)}}%
                </v-progress-circular>
              </div>
            </v-list-item-action>

            <v-dialog transition="dialog-bottom-transition" max-width="1400" v-model="h5ptasks[i].dialog" persistent content-class="h5pdialog" scrollable>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>
                    {{ item.title }}
                  </v-toolbar>
                  <v-card-text v-if="item.taskId==='r_4'">
                    <FromImages :ad-version="true" />
                  </v-card-text>
                  <v-card-text v-else>
                    <Tasks :id="item.taskId" :title="item.title"></Tasks>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="deActivateDialog(i)">{{$t('tasks.close')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>


          </v-list-item>
    </v-list-item-group>
  </v-list>
    <MainTour tour-id="tasks"/>
  </v-container>
  </div>
</template>

<script>
import h5ptasks from "@/assets/h5ptasks.json";
import h5ptasksen from "@/assets/h5ptasksen.json";
import Tasks from "@/components/Tasks/Tasks";
import MainTour from "@/components/Tours/MainTour";
import FImage from "@/components/FromImages/FImage";
import imagemaps from "@/assets/imagemaps.json";
import FromImages from "@/components/FromImages/FromImages";

export default {
  name: "TasksMenu",
  components: {FromImages, FImage, MainTour, Tasks},
  methods:{
    activateDialog(index){
      this.$store.commit("setH5PCurrentTask",this.h5ptasks[index].taskId);
      this.$set(this.h5ptasks[index],"dialog",true);
    },
    deActivateDialog(index){
      this.$set(this.h5ptasks[index],"dialog",false);
    },
    taskData(taskId) {
      return this.$store.getters.taskData(taskId);
    },
    progress(index,taskId){
      let data = this.taskData(taskId);
      return Math.floor(100*data.best_score/this.h5ptasks[index].maxScore);
    },

  },

  created() {
    let t=this;
    t.$store.commit("setCurrentOverallScore");
    setTimeout(function(){
      console.log(t.$store.state.moduleTasks.overallScore);
    },1000)
  },
  computed:{
    progressOverall(){
      return Math.round(100*this.$store.state.moduleTasks.overallScore/this.$store.state.stats.subtasksNo);
    }
  },
  data(){
    let cat  =this.$route.params.category;
    if(cat==null){
      cat="rek";
    }
    if(this.$store.state.language==="en")
      h5ptasks = h5ptasksen
    return{
      category: cat,
      h5ptasks:h5ptasks,
      imagemap:imagemaps[0],
    }
  },
  watch:{
    $route(to,from){
      this.category = this.$route.params.category;
    },
    '$store.state.moduleTasks.overallScore':function(){

    }
  }
}
</script>

<style>
.h5pdialog{
  margin:0!important;
}
.h5pdialog .v-card__text{
  padding:0px!important;
}
.h5pdialog .container{
  padding:0px!important;
}
.taskListAction{
  margin:0;
}

@media (max-width: 705px ) {
  .h5pdialog{
    display: block;
    height:99%;
    max-height: 99%!important;
  }
  .h5pdialog .v-card{
    height:100%;
  }
  .h5pdialog .v-toolbar{
    flex:0;
  }
  .h5pdialog #h5p-container{
    height: 87vh!important;
  }
}
</style>