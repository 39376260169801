<template>
  <span >
    {{myPriceWithTextPrefix}}
    <span class="currencySpan">
      <select v-model="currency" :items="allCurrencies" class="currencySelect">
        <option v-for="item in allCurrencies" :value="item">{{item}}</option>
      </select>
      <i aria-hidden="true" class="v-icon notranslate mdi mdi-menu-down theme--light"></i>
    </span>
    {{$t("landingpage.payment_monthly")}}
    <span class="currencySpan">
      <select v-model="period" :items="periods" class="paymentSelect">
        <option v-for="item in periods" :value="item">{{$t("landingpage."+item)}}</option>
      </select>
      <i aria-hidden="true" class="v-icon notranslate mdi mdi-menu-down theme--light"></i>
    </span>
    {{myPriceWithTextSuffix}}
  </span>
</template>

<script>
import myprice from "@/plugins/myprice";

export default {
  name: "PriceCurrency",
  mixins:[myprice],
  computed:{
    currency: {
      get () {
        return this.$store.state.currency
      },
      set (value) {
        this.$store.commit('setCurrency', value)
      }
    },
    period: {
      get () {
        return this.$store.state.pricing
      },
      set (value) {
        this.$store.commit('setPricing', value)
      }
    }
  },
  data() {
    return {
     // currency: this.$store.state.currency
      periods:[
          "annual",
          "monthly"
      ]
    }
  }
}
</script>

<style>
.currencySelect{
  display: inline-block;
  width:55px;
  border-bottom:2px solid #888 !important;
  border-radius: 2px;
  padding-left:5px;
  z-index:3;
  position: relative;
}
.paymentSelect{
  display: inline-block;
  width:145px;
  border-bottom:2px solid #888 !important;
  border-radius: 2px;
  padding-left:3px;
  z-index:3;
  position: relative;
}
.fontWhite .currencySelect{
  width:100px;
  color:white;
}
.fontWhite .paymentSelect{
  width:300px;
  color:white;
}
.currencySpan{
  position: relative;
}
.currencySpan i.v-icon{

  position:absolute;
  right:0px;
  top:calc(50% - 13px);
  z-index:1;
}
.fontWhite .currencySpan i.v-icon{
  color:white;
  top:calc(50% - 10px);
}
.paymentSelect option{
  color:black;
  font-size:16px;
}
.currencySelect option{
  color:black;
  font-size:16px;
}


</style>