<template>
  <div style="text-align: center;">

    <v-dialog transition="dialog-bottom-transition" max-width="900" v-model="show" content-class="dialogverbs">

      <template v-slot:default="dialog">

        <v-card>
          <v-toolbar color="primary" dark>
            <div style="width:100%;display:flex;align-items: center;">
              <div style="flex-grow:1;">{{$t('subscribe.title')}}</div>
              <div style="">
                <v-btn style="float:right" icon @click="show=false"><v-icon >mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-toolbar>
          <v-card-text>
            <p v-html="$t('subscribe.explanation')">
            </p>
            <p>
              <b>{{$t('landingpage.ctabuy4')}} <PriceCurrency/></b>
            </p>
            <v-form ref="form" v-model="valid" lazy-validation @submit="buy">
              <v-text-field v-model="email" readonly :rules="emailRules" :label="$t('landingpage.email')" required></v-text-field>
              <v-checkbox v-model="checkbox" :rules="[v => !!v || $t('landingpage.acceptTerms')]" :label="$t('landingpage.acceptanceTerms')" required></v-checkbox>
              <div style="text-align: center">
                <v-btn color="success" class="" @click="buy" x-large>{{$t('landingpage.ctabuynow')}}</v-btn>
              </div>
            </v-form>
            <v-alert class="mt-4" type="success" v-if="errorType === 'success'">
              {{$t('landingpage.ctasuccess')}}
            </v-alert>
            <v-alert class="mt-4" type="error" v-if="errorType === 'other'">
              {{$t('landingpage.ctaerror')}}
            </v-alert>
            <v-overlay :value="isAuthenticating" absolute="true">
              <v-progress-circular
                  indeterminate
                  size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-card-text>

        </v-card>

      </template>
    </v-dialog>
  </div>
</template>

<script>
import PriceNow from "@/components/LandingPage/PriceNow";
import myprice from "@/plugins/myprice";
import axios from "axios";
import PriceCurrency from "@/components/LandingPage/PriceCurrency";
export default {
  name: "Subscribe",
  components: {PriceCurrency, PriceNow},
  data(){
    return{
      isAuthenticating:false,
      errorType:"",
      email: this.inemail,
      emailRules: [
        v => !!v || this.$t('landingpage.ctavalidemail'),
        v => /.+@.+\..+/.test(v) || this.$t('landingpage.ctavalidemail'),
      ],
    }
  },
  watch:{
    show:function (to){
      console.log(this.show)
    },
    inemail:function(to){
      this.email = this.inemail
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  props:{
    value: Boolean,
      inemail:String
    },
  methods:{
    buy(event){
      event.preventDefault();
      let valid = this.$refs.form.validate();
      if(!valid) return;
      let code = this.$store.state.pricing;
      let email = this.email;
      let t=this;
      this.errorType = "";
      this.isAuthenticating = true;
      axios.post("/verbs/public/api/user/token",{email:email}).then(function(response) {
        t.isAuthenticating = false;
        if(response.data.success==="success"){
          t.errorType="success";
          window.setTimeout(function() {
            return window.location = "/Login?email=" + email;
          },5000);
        }
        if(response.data.error==="wrongUser" || response.data.error==="noSubscription"){
          t.isAuthenticating = true;
          return window.location = t.$t('config.paymentRedirectUrl') + "&price_code="+code+"&email="+email+"&currency="+t.$store.state.currency+"&language="+t.$i18n.locale()+"&version=2";
        }
      }).catch(function(response){
        t.isAuthenticating = false;
        t.errorType="other";
      });

    }
  },
  mixins:[myprice]
}
</script>

<style scoped>

</style>