<template>
  <v-app>

    <v-app-bar  clipped-left app class="d-flex width100" v-if="displayHeader">
      <v-app-bar-nav-icon @click="drawer = !drawer"   ></v-app-bar-nav-icon>

      <v-toolbar-title>
        {{$t('main.mainTitle')}}
        <span style="font-size:80%">---
          {{$t("main.subscriptionTo")}} {{$store.state.subscriptionTo}}
          </span>
      </v-toolbar-title>

      <div class="ml-auto" style="max-width: 300px;display:flex;">
       <!-- <v-btn class="mr-2" color="pink" fab dark small v-if="$store.state.tourAvailable" @click="$store.commit('startTour')">
          <v-icon dark>fa-solid fa-question</v-icon>
        </v-btn>-->
        <v-img max-height="50" max-width="150" src="@/assets/logo-1.png" style="float:right;"></v-img>
      </div>
    </v-app-bar>



    <v-navigation-drawer   v-model="drawer" class="deep-purple accent-4" dark
                           clipped  app>


      <v-list>
        <div v-for="item in items"
             :key="item.title" >
          <v-list-group  :prepend-icon="item.icon" no-action v-if="item.children" color="white" v-model="item.active">
            <template v-slot:activator >
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="child in item.children" :key="child.title" :to="child.to" link v-model="child.active" dense>
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :to="item.to" link >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </div>
       <!-- <v-list-item @click="changeLanguage">
          <v-list-item-icon>
            <v-icon>mdi-translate</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("main.changeLanguage") }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>-->
        <v-list-item @click="toPayments">
          <v-list-item-icon>
            <v-icon>mdi-credit-card-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("main.payments") }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
        <v-list-item @click="showLogout = true">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("main.logout") }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main >
      <!-- Sizes your content based upon application components -->


      <!-- Provides the application the proper gutter -->

      <router-view></router-view>

    </v-main>
    <Logout v-model="showLogout" />
    <div id="centerPoint"></div>
    <div id="fullscreen"></div>
    <form method="post" id="paymentForm" :action="$t('config.stripePortal')" target="_blank">
      <input type="hidden" name="token" :value="$store.state.persistent.token" />
    </form>
  </v-app>
</template>

<script>

import Logout from "@/components/Logout";
import axios from "axios";
export default {
  name: "Main",
  components: {Logout},
  beforeMount() {
    this.drawer = !this.isMobile;
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  watch:{
    '$store.state.tour':function(){
      if(this.$store.state.tour){
        this.displayHeader = false;
      }else{
        this.displayHeader = true;
      }
    }
  },
  methods:{
    changeLanguage(){
      //this.$store.commit("switchLanguage");
      var href = new URL(window.location);
      if(this.$i18n.locale()==="pl")
        href.searchParams.set('language', 'en');
      else
        href.searchParams.set('language', 'pl');
      window.location = href.toString();
    },
    toPayments(){
      document.getElementById("paymentForm").submit();

    }
  },
  data(){
    let drawer = true;
    return {
      showLogout:false,
      drawer: drawer,
      displayHeader:true,
      status: 'loading',
      items: this.$t('main.items'),
    }
  }
}
</script>

<style>
.width100 .v-toolbar__content{
  width: 100%;
}
#centerPoint{
  width:1px;
  height: 1px;
  background-color: rgba(255,255,255,0.01);
  position: absolute;
  top:0vh;
  left:50vw;
  z-index:-2;
}

#mainScreen{
  position: relative;

}
</style>