<template>
  <span>
      <span style="font-weight: bold"> {{myPriceWithCurrency}} </span>

  </span>
</template>

<script>
import myprice from "@/plugins/myprice";
export default {
  name: "PriceNow",
  data(){

    return{
    }
  },
  mixins:[myprice]
}
</script>

<style scoped>

</style>