<template>
  <v-container class="s2" fluid>
    <v-container>
      <v-row>
        <v-col cols="6" class="col-md-2" v-for="(item,i) in items" :key="i">
          <v-icon :color="iconColor" :size="iconSize">{{ item.icon }}</v-icon>
          <p>
            <span class="firstLine">{{item.no}}</span><br/>
            <span class="secondLine">{{item.text}}</span>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Icons",
  data(){
    return{
      iconColor:"white",
      iconSize:"50px",
      items:[
        { icon: "fa-solid fa-keyboard", no: this.$store.state.stats.verbsNo, text: this.$t('landingpage.verbs') },
        { icon: "fa-solid fa-list-check", no: this.$store.state.stats.tasksNo, text: this.$t('landingpage.taskSets') },
        { icon: "fa-solid fa-infinity", no: this.$store.state.stats.subtasksNo, text: this.$t('landingpage.exercises') },
        { icon: "fa-solid fa-video", no: "136", text: this.$t('landingpage.minutesOfRecordings') },
        { icon: "fa-solid fa-chalkboard-user", no: "!!!", text: this.$t('landingpage.norwegianTeacher') },
        { icon: "fa-solid fa-clipboard-question", no: this.$store.state.stats.verbsNo, text: this.$t('landingpage.flashcards2') }
      ]
      ,
    }
  }
}
</script>

<style scoped>
.s2{
  background-color: #001253;
  font-size: 18pt;
  color: white;
  text-align: center;
}
.firstLine{
  font-weight: bold;
  font-size:22pt;
}
@media screen and (max-width: 600px) {
  .s2{
    font-size: 16pt;
    clear: both;
    margin-top: 0px;
  }
}
@media screen and (max-width: 300px) {
  .s2{
    font-size: 12pt;
  }
}
</style>