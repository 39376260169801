<template>
  <v-tour name="tour" :steps="steps" :callbacks="callbacks" :options="options">
    <template slot-scope="tour">
      <transition name="fade">
        <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="labels"
            :finish="tour.finish"
        >

        </v-step>
      </transition>
    </template>
  </v-tour>
</template>

<script>
import tours from "./tours";
export default {
  name: "MainTour",
  props:{
    tourId:String
  },
  methods:{
    nextStep(element){
      const elementSelector = element.target;
      const elementHighlighted = element.targetHighlighted;
      Array.from(document.querySelectorAll('.overElementScreen')).forEach((el) => el.classList.remove('overElementScreen'));
      Array.from(document.querySelectorAll('.overElement')).forEach((el) => el.classList.remove('overElement'));
        if(typeof elementHighlighted ==='undefined')
          return document.querySelector(elementSelector).classList.add("overElement");
        if(elementHighlighted!==null)
          return document.querySelector(elementHighlighted).classList.add("overElement");
        return document.querySelector(elementSelector).classList.add("overElementScreen");


    },
    finish(){
      Array.from(document.querySelectorAll('.overElementScreen')).forEach((el) => el.classList.remove('overElementScreen'));
      Array.from(document.querySelectorAll('.overElement')).forEach((el) => el.classList.remove('overElement'));
      this.$store.commit("endTour");
    },
    start(){
    }
  },
  created() {
    let t=this;
    window.setTimeout(function() {
      t.$store.commit("tourAvailable", true);
      t.$store.commit("endTour");
    },100);
  },
  destroyed() {
    this.$store.commit("tourAvailable",false);
  },
  watch:{
    '$store.state.tour':function(){
      let t=this;

      if(this.$store.state.tour){
        window.setTimeout(function() {
          t.$tours['tour'].start();
        },100);

      }
    }
  },
  data () {
    let t = this;
    let steps = tours[this.tourId].steps;
    console.log(steps);
    steps.forEach((element)=>{
      element.before = type => new Promise((resolve, reject) => {
        if(typeof element.beforeAdditional !=='undefined'){
          element.beforeAdditional(resolve,t.nextStep,element);
        }else {
          t.nextStep(element);
          setTimeout(function() {
            resolve();
          },100);
        }
      })
    });
    console.log(steps);
    return {
      steps:steps,
      callbacks:{
        onFinish:t.finish,
        onStart:t.start,
        onStop:t.finish
      },
      labels: {
        buttonSkip: 'Wyjdź',
        buttonPrevious: null,
        buttonNext: 'Następny krok',
        buttonStop: 'Koniec!'
      },
      options:{
        useKeyboardNavigation: false,
      }
    }
  },
}
</script>
<style>
.overElement{
  box-shadow: 0 0 0 max(1000vh, 1000vw) rgba(0, 0, 0, .3);
  position:relative!important;
  z-index:1000;
  border:4px dotted pink;
  padding:20px 0px 10px 0px!important;
}
.overElementScreen{
  box-shadow: 0 0 0 max(1000vh, 1000vw) rgba(0, 0, 0, .3);
  z-index:1000!important;
}
.nodisplay{
  display:none!important;
}
.v-step__button-previous{
  display:none!important;
}
</style>
<style scoped>

</style>