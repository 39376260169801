<template>
  <v-form v-model="valid">
    <v-container><label>{{$t('flashcards.difficultyLevel')}}</label><br/>
      <ConfigRadio :value="flashCards.level" field="level" @input="updateConfig('level', $event)" :button-values="this.levelValues"></ConfigRadio>
    </v-container>

    <v-container><label>{{$t('flashcards.learnDirection')}}</label><br/>
      <ConfigRadio :value="flashCards.side" field="side" @input="updateConfig('side', $event)" :button-values="this.sideValues"></ConfigRadio>
    </v-container>

    <v-container><label>{{$t('flashcards.numberOfWords')}}</label><br/>
      <v-text-field :value="flashCards.words_per_session" @input="updateConfig('words_per_session', $event)" type="number" :rules="numberRules" class="configInput"></v-text-field>
    </v-container>

    <v-container><label>{{$t('flashcards.displayWords')}}</label><br/>
      <ConfigRadio :value="flashCards.known_words" field="level" @input="updateConfig('known_words', $event)" :button-values="this.knownWordsValues"></ConfigRadio>
    </v-container>

    <v-container><label>{{$t('flashcards.displayImages')}}</label><br/>
      <ConfigRadio :value="flashCards.show_images" field="level" @input="updateConfig('show_images', $event)" :button-values="this.showImagesValues"></ConfigRadio>
    </v-container>
  </v-form>

</template>

<script>
import ConfigRadio from "@/components/Flashcards/ConfigRadio";
export default {
  name: "FlashcardsConfig",
  components: {ConfigRadio},
  computed:{

  },
  methods:{
    updateConfig(name,val){
      this.$store.dispatch("setFlashCardConfig",{field:name,value:val});
    },
  },
  data(){
    return {
      flashCards: this.$store.state.flashCards,
      levelValues:this.$t('flashcards.levelValues'),
      sideValues:this.$t('flashcards.sideValues'),
      knownWordsValues:this.$t('flashcards.knownWordsValues'),

      showImagesValues:this.$t('flashcards.showImagesValues'),
      test:"2",
      valid: false,
      numberRules:[
        value => !!value || this.$t('flashcards.required'),
        value => parseInt(value)>0 && parseInt(value)<=1000 || this.$t('flashcards.number1to1000')
      ],
    }
  },
}
</script>

<style scoped>
.configInput{
  padding-top:0;
  margin-top:0;
}
</style>