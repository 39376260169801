<template>
<div id="home">
  <v-fade-transition >
      <v-container fluid fill-height v-if="currentSlide==='main'">

        <v-layout  justify-center>
          <transition name="fade">
          <video autoplay loop muted id="bgvideo" v-if="currentSlide==='main'">
            <source src="@/assets/background.mp4">
          </video>
          </transition>
          <div class="homecontent" >
            <div style="display: table;">
              <div style="display: table-row;">
                <div style="display: table-cell;">
                  <span class="text-h2 white--text font-weight-bold shadowed">{{$t('home.readyForAdventure')}}</span>
                </div>
              </div>
              <div style="display: table-row;">
                <div style="display: table-cell; text-align: right;padding-top:30px;">
                  <v-btn class="primary btngo" x-large rounded color="success" @click="changeSlide">{{$t('home.letsGo')}}</v-btn>
                </div>
              </div>
            </div>




          </div>
        </v-layout>

      </v-container>
  </v-fade-transition>
  <v-scale-transition >
    <v-container class="pa-2" v-if="currentSlide==='history'" >
    <v-timeline  :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item v-for="t in timeline" :key="t.id" :color="t.color" :icon="t.icon" >
        <v-card class="elevation-2" :color="t.color" dark>
          <v-card-title class="text-h6" :id="'card'+t.id">
            {{t.title}}
          </v-card-title>
          <v-card-text class="white text--primary pt-2">
            <p>{{t.text}}</p>
            <v-btn :color="t.color" class="mx-0" outlined :to="t.buttonTo">
              {{ t.buttonTxt }}
            </v-btn>
          </v-card-text>
        </v-card>
        <template v-slot:opposite>
          <span class="headline font-weight-bold" :style="'color:'+t.color" >{{t.text2}}</span>
        </template>
      </v-timeline-item>


    </v-timeline>

  </v-container>
  </v-scale-transition>

  <MainTour tour-id="home" />
</div>
</template>

<script>
import MainTour from "@/components/Tours/MainTour";
export default {
  name: "Home",
  components: {MainTour},
  data(){
    let currentSlide = this.$store.state.persistent.firstSlide;
    return {
      currentSlide:currentSlide,
      timeline:this.$t("home.timeline")
    }
  },
  methods:{
    changeSlide(){
      this.currentSlide="change"
      window.setTimeout((par)=>{
        par.currentSlide = "history";
        this.$store.commit("setFirstSlide","history");
      },1000,this);
    }
  },


}
</script>

<style >
#bgvideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width:100%;
  height:100%;
}
.btngo{
  margin-right: 40px;
}
.shadowed{
  text-shadow: 2px 2px #000000;
}
.homecontent{
  position:relative;
  z-index: 1;
  margin-top:30vh;
}
#home .scale-transition-enter-active {
  transition: all 1s ease-out!important;
}
#home .fade-transition-leave-active {
  transition: all 1s ease-in!important;
}
</style>